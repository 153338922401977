import React, { useState, useEffect, Fragment } from 'react';
import { Translate } from 'react-redux-i18n';
import { configuredMoment as moment } from '../../utils';
import './MaintenanceBanner.scss';

const MaintenanceBanner = ({ startTime }) => {
  const [duration, setDuration] = useState(moment.duration(startTime.diff(moment())));
  const [intervalId, setIntervalId] = useState();

  useEffect(() => {
    if (startTime.isAfter(moment())) {
      const intervalId = setInterval(() => {
        setDuration(moment.duration(startTime.diff(moment())));
      }, 1000);
      setIntervalId(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, []);

  if (duration.asSeconds() <= 0) {
    clearInterval(intervalId);
    return null;
  }

  return (
    <Fragment>
      <div className="banner-margin"></div>
      <div className="global-banner">
        <Translate value="maintenance_mode.banner" remainingTime={startTime.fromNow(true)} />
      </div>
    </Fragment>
  );
};

export default MaintenanceBanner;
