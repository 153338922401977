import { I18n } from 'react-redux-i18n';
import * as types from '../constants/actionTypes';
import {
  ALLERGY_DSS_ITEM_ID,
  HTTP_STATUS_CODES,
  CAREGIVER_ROLE,
  INBOX_VISITED_PATIENTS_KEY,
  TEMPLATE_TYPE,
  ASSESSMENT_STATUS
} from '../constants';
import api from '../api/apiClient';
import { getMimetype } from '../utils';
import { loadState, saveState } from '../services/localStorage';

/**
 * Notification actions
 */

export const showNotification = (message, notificationType, anchorOrigin) => {
  return {
    type: types.SHOW_NOTIFICATION_MESSAGE,
    message,
    notificationType,
    anchorOrigin
  };
};

export const hideNotification = () => {
  return {
    type: types.HIDE_NOTIFICATION_MESSAGE
  };
};

/**
 * PAL
 */

const getNewMembersRequest = () => {
  return {
    type: types.GET_NEW_MEMBERS_REQUEST
  };
};

const getNewMembersSuccess = (response, isNurse) => {
  return {
    type: types.GET_NEW_MEMBERS_SUCCESS,
    payload: response,
    isNurse
  };
};

const getNewMembersError = (error) => {
  return {
    type: types.GET_NEW_MEMBERS_ERROR,
    payload: error
  };
};

export const getNewMembers = (jwtToken, isNurse) => {
  return (dispatch) => {
    dispatch(getNewMembersRequest());

    api
      .getNewMembers(jwtToken)
      .then((response) => {
        dispatch(getNewMembersSuccess(response, isNurse));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getNewMembersError(error));
      });
  };
};

const assignResponsibleDoctorRequest = (memberGuid) => {
  return {
    type: types.ASSIGN_PAL_REQUEST,
    payload: memberGuid
  };
};

const assignResponsibleDoctorSuccess = (response, isNurse) => {
  return {
    type: types.ASSIGN_PAL_SUCCESS,
    payload: response,
    isNurse
  };
};

const assignResponsibleDoctorError = (error) => {
  return {
    type: types.ASSIGN_PAL_ERROR,
    payload: error
  };
};

export const assignResponsibleDoctor = (jwtToken, memberGuid, isNurse) => {
  return (dispatch) => {
    dispatch(assignResponsibleDoctorRequest(memberGuid));

    api
      .assignResponsibleDoctor(jwtToken, memberGuid)
      .then((response) => {
        dispatch(assignResponsibleDoctorSuccess(response, isNurse));
        dispatch(
          showNotification(I18n.t(`notification.assign_responsible_${isNurse ? 'nurse' : 'doctor'}.success`), 'success')
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(assignResponsibleDoctorError(error));
        dispatch(
          showNotification(I18n.t(`notification.assign_responsible_${isNurse ? 'nurse' : 'doctor'}.error`), 'error')
        );
      });
  };
};

export const clearAssignedMembers = () => {
  return {
    type: types.CLEAR_ASSIGNED_MEMBERS
  };
};

/**
 * Authentication actions
 */
export const loginRequest = () => {
  return {
    type: types.LOGIN_REQUEST
  };
};

export const loginSuccess = (response) => {
  return {
    type: types.LOGIN_SUCCESS,
    payload: response
  };
};

export const loginError = (response) => {
  return {
    type: types.LOGIN_ERROR,
    payload: response
  };
};

export const logout = () => {
  return {
    type: types.LOGOUT
  };
};

export const refreshTokenRequest = () => {
  return {
    type: types.REFRESH_TOKEN_REQUEST
  };
};

export const refreshTokenSuccess = (response) => {
  return {
    type: types.REFRESH_TOKEN_SUCCESS,
    payload: response
  };
};

export const refreshToken = (jwtToken, requestId) => {
  return (dispatch) => {
    dispatch(refreshTokenRequest());

    api
      .refreshToken(jwtToken, requestId)
      .then((response) => {
        dispatch(refreshTokenSuccess(response));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const manuallyRefreshTokenRequest = () => {
  return {
    type: types.MANUAL_TOKEN_REFRESH_REQUEST
  };
};

const manuallyRefreshTokenSuccess = (response) => {
  return {
    type: types.MANUAL_TOKEN_REFRESH_SUCCESS,
    payload: response
  };
};

export const manuallyRefreshToken = (jwtToken, requestId) => {
  return (dispatch) => {
    dispatch(manuallyRefreshTokenRequest());

    api
      .refreshToken(jwtToken, requestId)
      .then((response) => {
        dispatch(manuallyRefreshTokenSuccess(response));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const checkTokenValidity = () => {
  return {
    type: types.CHECK_TOKEN_VALIDITY
  };
};

/**
 * Member data actions
 */

const patientDataRequest = () => {
  return {
    type: types.PATIENT_DATA_REQUEST
  };
};

const patientDataSuccess = (response) => {
  return {
    type: types.PATIENT_DATA_SUCCESS,
    payload: response
  };
};

const patientDataError = (response) => {
  return {
    type: types.PATIENT_DATA_ERROR,
    payload: response
  };
};

export const getPatientData = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(patientDataRequest());
    dispatch(chaptersRequest());

    api
      .getHealthProfileChapters(jwtToken, memberGuid)
      .then((response) => {
        dispatch(chaptersSuccess(response));

        return api.getHealthProfile(jwtToken, memberGuid);
      })
      .then((response) => {
        dispatch(patientDataSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(patientDataError(error));
      });
  };
};

export const clearPatientData = (disableTracking) => {
  return {
    type: types.CLEAR_PATIENT_DATA,
    payload: { disableTracking }
  };
};

/**
 * Member list actions
 */

const getMembersRequest = (namespace) => {
  return {
    type: types[`GET_${namespace}_MEMBERS_REQUEST`],
    namespace
  };
};

const getMembersSuccess = (namespace, response, currentUser, isNurse) => {
  return {
    type: types[`GET_${namespace}_MEMBERS_SUCCESS`],
    payload: response,
    currentUser,
    isNurse,
    namespace
  };
};

const getMembersError = (namespace, response) => {
  return {
    type: types[`GET_${namespace}_MEMBERS_ERROR`],
    payload: response,
    namespace
  };
};

export const getMembers = (namespace, jwtToken, offset, limit, query, currentUser, isNurse, sort) => {
  return (dispatch) => {
    dispatch(getMembersRequest(namespace));

    const extraQuery =
      namespace === 'ACTIVE'
        ? '(serviceStatus==doctor,serviceStatus==nurse)'
        : '(serviceStatus==hidden,serviceStatus==blocked,serviceStatus==deactivating)';

    api
      .getMembersList(jwtToken, offset, limit, `${query}${query.length ? ';' : ''}${extraQuery}`, sort, [
        'concatenatedNames'
      ])
      .then((response) => {
        dispatch(getMembersSuccess(namespace, response, currentUser, isNurse));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMembersError(namespace, error));
      });
  };
};

export const setCurrentPage = (namespace, pageNumber) => {
  return {
    type: types[`SET_CURRENT_PAGE_${namespace}`],
    payload: pageNumber,
    namespace
  };
};

export const setSearchQuery = (namespace, searchQuery, searchTerm) => {
  return {
    type: types[`SET_SEARCH_QUERY_${namespace}`],
    payload: {
      searchQuery,
      searchTerm
    },
    namespace
  };
};
/**
 * Chat message actions
 */

const getChatMessagesRequest = () => {
  return {
    type: types.GET_CHAT_MESSAGES_REQUEST
  };
};

const getChatMessagesSuccess = (result) => {
  return {
    type: types.GET_CHAT_MESSAGES_SUCCESS,
    payload: result
  };
};

const getChatMessagesError = (error) => {
  return {
    type: types.GET_CHAT_MESSAGES_ERROR,
    payload: error
  };
};

export const postChatMessageRequest = () => {
  return {
    type: types.POST_CHAT_MESSAGE_REQUEST
  };
};

export const postChatMessageSuccess = (message, authorName, responseType, hasEditedDraft, isNurse, authorGuid) => {
  return {
    type: types.POST_CHAT_MESSAGE_SUCCESS,
    payload: message,
    authorName,
    responseType,
    hasEditedDraft,
    isNurse,
    authorGuid
  };
};

export const postChatMessageError = (error) => {
  return {
    type: types.POST_CHAT_MESSAGE_ERROR,
    payload: error
  };
};

const deleteChatMessageDraftRequest = () => {
  return {
    type: types.DELETE_CHAT_DRAFT_REQUEST
  };
};

const deleteChatMessageDraftSuccess = (messageId, removeFromUI, encounterId) => {
  return {
    type: types.DELETE_CHAT_DRAFT_SUCCESS,
    payload: {
      messageId,
      removeFromUI,
      encounterId
    }
  };
};

const deleteChatMessageDraftError = (error) => {
  return {
    type: types.DELETE_CHAT_DRAFT_ERROR,
    payload: error
  };
};

export const updateDraftCountdown = () => {
  return {
    type: types.UPDATE_DRAFT_COUNTDOWN
  };
};

export const showEditDraftModal = () => {
  return {
    type: types.START_EDITING_DRAFT
  };
};

export const startEditingDraft = (token, memberGuid, message) => {
  return (dispatch) => {
    dispatch(deleteChatMessageDraftRequest());

    return api
      .deleteChatMessage(token.jwt, memberGuid, message.id)
      .then(() => {
        dispatch(deleteChatMessageDraftSuccess(message.id, false, message.encounterId));
        dispatch(showEditDraftModal());
      })
      .catch((error) => {
        console.log(error);
        dispatch(deleteChatMessageDraftError(error));
      });
  };
};

export const updateDraftText = (event) => {
  return {
    type: types.UPDATE_DRAFT_TEXT,
    payload: event.target.value
  };
};

export const updateMessageText = (event) => {
  return {
    type: types.UPDATE_MESSAGE_TEXT,
    payload: event.target.value
  };
};

export const toggleChatModal = () => {
  return {
    type: types.TOGGLE_CHAT_MODAL
  };
};

export const populateCannedResponse = (cannedResponse) => {
  return {
    type: types.POPULATE_CANNED_RESPONSE,
    payload: cannedResponse
  };
};

export const populateNudgeMessage = (nudgeMessage) => {
  return {
    type: types.POPULATE_NUDGE_MESSAGE,
    payload: nudgeMessage
  };
};

export const setMessageType = (messageType) => {
  return {
    type: types.SET_MESSAGE_TYPE,
    payload: messageType
  };
};

export const clearChatMessages = () => {
  return {
    type: types.CLEAR_CHAT_MESSAGES
  };
};

const getChatResponsesRequest = () => {
  return {
    type: types.GET_CHAT_RESPONSES_REQUESTS
  };
};

const getChatResponsesSuccess = (response) => {
  return {
    type: types.GET_CHAT_RESPONSES_SUCCESS,
    payload: response
  };
};

const getChatResponsesError = (error) => {
  return {
    type: types.GET_CHAT_RESPONSES_ERROR,
    payload: error
  };
};

export const getChatResponses = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getChatResponsesRequest());

    api
      .getChatResponses(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getChatResponsesSuccess(response.body));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getChatResponsesError(error));
      });
  };
};

export const toggleChatResponseMenu = (isOpen) => {
  return {
    type: types.TOGGLE_CHAT_RESPONSE_MENU,
    payload: isOpen
  };
};

export const setInputScroll = (bool) => {
  return {
    type: types.SET_INPUT_SCROLL,
    payload: bool
  };
};

export const setChatMessageHighlight = (id, searchText, type) => {
  return {
    type: types.SET_CHAT_MESSAGE_HIGHLIGHT,
    payload: {
      id,
      searchText,
      type
    }
  };
};

export const toggleChatMessageFilter = (messageType) => {
  return {
    type: types.TOGGLE_CHAT_MESSAGE_FILTER,
    payload: messageType
  };
};

export const setChatImageUrl = (objectUrl, messageId) => {
  return {
    type: types.SET_CHAT_IMAGE_URL,
    payload: {
      objectUrl,
      messageId
    }
  };
};

const convertDraftToMessageRequest = () => {
  return {
    type: types.CONVERT_DRAFT_TO_MESSAGE_REQUEST
  };
};

const convertDraftToMessageSuccess = (messageId) => {
  return {
    type: types.CONVERT_DRAFT_TO_MESSAGE_SUCCESS,
    payload: {
      messageId
    }
  };
};

const convertDraftToMessageError = (error) => {
  return {
    type: types.CONVERT_DRAFT_TO_MESSAGE_ERROR,
    payload: error
  };
};

export const convertDraftToMessage = (token, memberGuid, messageId, cb) => {
  return (dispatch) => {
    dispatch(convertDraftToMessageRequest());

    api
      .convertDraftToMessage(token.jwt, memberGuid, messageId)
      .then(() => {
        dispatch(convertDraftToMessageSuccess(messageId));
        cb && cb();
      })
      .catch((error) => {
        console.log(error);
        dispatch(showNotification(I18n.t('notification.convert_draft_to_message.error'), 'error'));
        dispatch(convertDraftToMessageError(error));
      });
  };
};

export const clearChatMessage = () => {
  return {
    type: types.CLEAR_MESSAGE
  };
};

/**
 * Chat message thunks
 */

export const getChatMessages = (token, memberGuid, cb) => {
  return (dispatch) => {
    dispatch(getChatMessagesRequest());

    return api
      .getChatMessages(token.jwt, memberGuid)
      .then((result) => {
        dispatch(getChatMessagesSuccess(result));
        typeof cb === 'function' && cb();
      })
      .catch((error) => {
        console.log(error);
        dispatch(getChatMessagesError(error));
      });
  };
};

export const postChatMessage = (
  token,
  memberGuid,
  message,
  fileInfo,
  authorName,
  responseType,
  hasEditedDraft,
  isNurse,
  authorGuid
) => {
  return (dispatch) => {
    dispatch(postChatMessageRequest());

    return api
      .postChatMessage(token.jwt, memberGuid, message, fileInfo)
      .then((createdMessage) => {
        dispatch(postChatMessageSuccess(createdMessage, authorName, responseType, hasEditedDraft, isNurse, authorGuid));
      })
      .catch((error) => {
        console.log(error);
        dispatch(postChatMessageError(error));
      });
  };
};

export const deleteChatMessageDraft = (token, memberGuid, messageId) => {
  return (dispatch) => {
    dispatch(deleteChatMessageDraftRequest());

    return api
      .deleteChatMessage(token.jwt, memberGuid, messageId)
      .then(() => {
        dispatch(deleteChatMessageDraftSuccess(messageId, true));
      })
      .catch((error) => {
        console.log(error);
        dispatch(deleteChatMessageDraftError(error));
      });
  };
};

export const chaptersRequest = () => {
  return {
    type: types.GET_CHAPTERS_REQUEST
  };
};

export const chaptersSuccess = (response) => {
  return {
    type: types.GET_CHAPTERS_SUCCESS,
    payload: response
  };
};

export const chaptersError = (response) => {
  return {
    type: types.GET_CHAPTERS_ERROR,
    payload: response
  };
};

const setCurrentMember = (member, caregiverGuid) => {
  return {
    type: types.SET_CURRENT_MEMBER,
    payload: member,
    caregiverGuid
  };
};

const getUserProfileRequest = () => {
  return {
    type: types.GET_USER_PROFILE_REQUEST
  };
};

const getUserProfileError = (response) => {
  return {
    type: types.GET_USER_PROFILE_ERROR,
    payload: response
  };
};

export const getUserProfile = (jwtToken, memberGuid, caregiverGuid) => {
  return (dispatch) => {
    dispatch(clearProfileImage());
    dispatch(setCurrentMember({ user: { guid: memberGuid } }));
    dispatch(getUserProfileRequest());

    return api
      .getUserProfileWithUserExtras(jwtToken, memberGuid, ['paymentExemption', 'armCircumference'])
      .then((response) => {
        dispatch(setCurrentMember(response.members[0], caregiverGuid));

        if (response.members[0].user.profileImage) {
          dispatch(getProfileImageRequest());
          return api
            .getFile(jwtToken, memberGuid, response.members[0].user.profileImage.id, 100, 100)
            .then((response) => {
              dispatch(getProfileImageSuccess(response.response.body));
            })
            .catch((error) => {
              dispatch(getProfileImageError(error));
              console.log(error);
            });
        }
      })
      .catch((error) => {
        dispatch(getUserProfileError(error));
        console.log(error);
      });
  };
};

const getProfileImageRequest = () => {
  return {
    type: types.GET_PROFILE_IMAGE_REQUEST
  };
};

const getProfileImageSuccess = (image) => {
  return {
    type: types.GET_PROFILE_IMAGE_SUCCESS,
    payload: image
  };
};

const getProfileImageError = (error) => {
  return {
    type: types.GET_PROFILE_IMAGE_ERROR,
    payload: error
  };
};

const clearProfileImage = () => {
  return {
    type: types.CLEAR_PROFILE_IMAGE
  };
};

export const clearCurrentMember = () => {
  return {
    type: types.CLEAR_CURRENT_MEMBER
  };
};

const getMemberDevicesRequest = () => {
  return {
    type: types.GET_MEMBER_DEVICES_REQUEST
  };
};

const getMemberDevicesSuccess = (response) => {
  return {
    type: types.GET_MEMBER_DEVICES_SUCCESS,
    payload: response
  };
};

const getMemberDevicesError = (response) => {
  return {
    type: types.GET_MEMBER_DEVICES_ERROR,
    payload: response
  };
};

export const getMemberDevices = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberDevicesRequest());

    return api
      .getMemberDevices(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberDevicesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberDevicesError(error));
      });
  };
};

const markMemberAsInactiveRequest = () => {
  return {
    type: types.MARK_MEMBER_INACTIVE_REQUEST
  };
};

const markMemberAsInactiveSuccess = (response) => {
  return {
    type: types.MARK_MEMBER_INACTIVE_SUCCESS,
    payload: response
  };
};

const markMemberAsInactiveError = (response) => {
  return {
    type: types.MARK_MEMBER_INACTIVE_ERROR,
    payload: response
  };
};

export const markMemberAsInactive = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(markMemberAsInactiveRequest());

    return api
      .updateMember(jwtToken, memberGuid, { serviceStatus: 'hidden' })
      .then((response) => {
        dispatch(markMemberAsInactiveSuccess(response));
        dispatch(showNotification(I18n.t('patient_view.overview.marked_inactive_success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(markMemberAsInactiveError(error));
        dispatch(showNotification(I18n.t('patient_view.overview.marked_inactive_error'), 'error'));
      });
  };
};

const getMeasurementsRequest = () => {
  return {
    type: types.MEMBER_MEASUREMENTS_REQUEST
  };
};

const getMeasurementsSuccess = (response) => {
  return {
    type: types.MEMBER_MEASUREMENTS_SUCCESS,
    payload: response
  };
};

const getMeasurementsError = (response) => {
  return {
    type: types.MEMBER_MEASUREMENTS_ERROR,
    payload: response
  };
};

export const getBloodPressureMeasurements = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMeasurementsRequest());

    return api
      .getBloodPressureMeasurements(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMeasurementsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMeasurementsError(error));
      });
  };
};

/**
 * Medication actions
 */

const getPrescriptionRequest = () => {
  return {
    type: types.GET_PRESCRIPTION_REQUEST
  };
};

const getPrescriptionSuccess = (response) => {
  return {
    type: types.GET_PRESCRIPTION_SUCCESS,
    payload: response
  };
};

const getPrescriptionError = (error) => {
  return {
    type: types.GET_PRESCRIPTION_ERROR,
    payload: error
  };
};

export const getPrescription = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getPrescriptionRequest());

    api
      .getPrescriptionSession(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getPrescriptionSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getPrescriptionError(error));
        dispatch(showNotification(I18n.t('patient_view.medications.get_prescription_session_error'), 'error'));
      });
  };
};

const postPrescriptionRequest = () => {
  return {
    type: types.POST_PRESCRIPTION_REQUEST
  };
};

const postPrescriptionSuccess = (response) => {
  return {
    type: types.POST_PRESCRIPTION_SUCCESS,
    payload: response
  };
};

const postPrescriptionError = (error) => {
  return {
    type: types.POST_PRESCRIPTION_ERROR,
    payload: error
  };
};

export const postPrescription = (jwtToken, memberGuid, prescriptionSession) => {
  return (dispatch) => {
    dispatch(postPrescriptionRequest());

    api
      .postPrescriptionSession(jwtToken, memberGuid, prescriptionSession)
      .then((response) => {
        dispatch(postPrescriptionSuccess(response));
        const notificationMessage = I18n.t(
          `patient_view.medications.medication${response.prescription.length > 1 ? 's' : ''}_prescribed_notification`
        ).replace(
          '{0}',
          response.prescription.length > 1 ? response.prescription.length : response.prescription[0].current.name
        );

        dispatch(showNotification(notificationMessage, 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(postPrescriptionError(error));
        dispatch(showNotification(I18n.t('patient_view.medications.post_prescription_error'), 'error'));
      });
  };
};

export const openAlfaEReceptModal = () => {
  return {
    type: types.OPEN_ALFA_ERECEPT_MODAL
  };
};

export const closeAlfaEReceptModal = () => {
  return {
    type: types.CLOSE_ALFA_ERECEPT_MODAL
  };
};

const getMemberMedicationsRequest = () => {
  return {
    type: types.GET_MEMBER_MEDICATIONS_REQUEST
  };
};

const getMemberMedicationsSuccess = (response) => {
  return {
    type: types.GET_MEMBER_MEDICATIONS_SUCCESS,
    payload: response
  };
};

const getMemberMedicationsError = (error) => {
  return {
    type: types.GET_MEMBER_MEDICATIONS_ERROR,
    payload: error
  };
};

export const getMemberMedications = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberMedicationsRequest());

    api
      .getMemberMedications(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberMedicationsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberMedicationsError(error));
      });
  };
};

const getMemberMedicineExtrasRequest = () => {
  return {
    type: types.GET_MEMBER_MEDICINE_EXTRAS_REQUEST
  };
};

const getMemberMedicineExtrasSuccess = (response) => {
  return {
    type: types.GET_MEMBER_MEDICINE_EXTRAS_SUCCESS,
    payload: response
  };
};

const getMemberMedicineExtrasError = (error) => {
  return {
    type: types.GET_MEMBER_MEDICINE_EXTRAS_ERROR,
    payload: error
  };
};

export const getMemberMedicineExtras = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberMedicineExtrasRequest());

    api
      .getMemberMedicineExtras(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberMedicineExtrasSuccess(response));
      })
      .catch((error) => {
        // Todo: handle 404
        console.log(error);
        dispatch(getMemberMedicineExtrasError(error));
      });
  };
};

const editMedicationRequest = () => {
  return {
    type: types.EDIT_MEDICATION_REQUEST
  };
};

const editMedicationSuccess = (response) => {
  return {
    type: types.EDIT_MEDICATION_SUCCESS,
    payload: response
  };
};

const editMedicationError = (error) => {
  return {
    type: types.EDIT_MEDICATION_ERROR,
    payload: error
  };
};

export const editMedication = (jwtToken, memberGuid, medicationId, medicationEdits, changeType) => {
  return (dispatch) => {
    dispatch(editMedicationRequest());

    api
      .editMedication(jwtToken, memberGuid, medicationId, medicationEdits, changeType)
      .then((response) => {
        dispatch(editMedicationSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(editMedicationError(error));
      });
  };
};

const signMedicationRequest = () => {
  return {
    type: types.SIGN_MEDICATION_REQUEST
  };
};

const signMedicationSuccess = (response, medication, user) => {
  return {
    type: types.SIGN_MEDICATION_SUCCESS,
    payload: {
      response,
      medication,
      user
    }
  };
};

const signMedicationError = (error, medicationId) => {
  return {
    type: types.SIGN_MEDICATION_ERROR,
    payload: {
      error,
      medicationId
    }
  };
};

export const signMedication = (jwtToken, memberGuid, medication, user, comment) => {
  return (dispatch) => {
    dispatch(signMedicationRequest());

    api
      .editMedication(jwtToken, memberGuid, medication.id, medication.current, 'sign', comment)
      .then((response) => {
        dispatch(signMedicationSuccess(response, medication, user));
        dispatch(showNotification(I18n.t('notification.medication_signed.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        if (error.status === HTTP_STATUS_CODES.CONFLICT) {
          dispatch(signMedicationError(error, medication.id));
        } else {
          dispatch(showNotification(I18n.t('notification.medication_signed.error'), 'error'));
          dispatch(signMedicationError(error));
        }
      });
  };
};

export const dismissSigningErrorMessage = () => {
  return {
    type: types.DISMISS_SIGNING_ERROR_MESSAGE
  };
};

export const selectMedication = (selectedMedication) => {
  return (dispatch) => {
    return () => {
      dispatch({
        type: types.SELECT_MEDICATION,
        payload: selectedMedication
      });
    };
  };
};

export const showSignMedicationModal = () => {
  return {
    type: types.SHOW_SIGN_MEDICATION_MODAL
  };
};

export const hideSignMedicationModal = () => {
  return {
    type: types.HIDE_SIGN_MEDICATION_MODAL
  };
};

export const closeSignMedicationModal = () => {
  return {
    type: types.CLOSE_SIGN_MEDICATION_MODAL
  };
};

export const updateMedicationProperty = (property, event) => {
  return {
    type: types.UPDATE_MEDICATION_PROPERTY,
    payload: {
      property,
      event
    }
  };
};

export const updateCurrentMedicationProperty = (property, event) => {
  return {
    type: types.UPDATE_CURRENT_MEDICATION_PROPERTY,
    payload: {
      property,
      event
    }
  };
};

const searchMedicationAlternativesRequest = () => {
  return {
    type: types.SEARCH_MEDICATION_ALTERNATIVES_REQUEST
  };
};

const searchMedicationAlternativesSuccess = (response) => {
  return {
    type: types.SEARCH_MEDICATION_ALTERNATIVES_SUCCESS,
    payload: response
  };
};

const searchMedicationAlternativesError = (error) => {
  return {
    type: types.SEARCH_MEDICATION_ALTERNATIVES_ERROR,
    payload: error
  };
};

export const searchMedicationAlternatives = (jwtToken, barcode) => {
  return (dispatch) => {
    dispatch(searchMedicationAlternativesRequest());

    api
      .searchMedications(jwtToken, `itemNumber==${barcode}`)
      .then((response) => {
        dispatch(searchMedicationAlternativesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(searchMedicationAlternativesError(error));
      });
  };
};

export const selectMedicationConflictOption = (e) => {
  return {
    type: types.SELECT_MEDICATION_CONFLICT_OPTION,
    payload: window.parseInt(e.target.value, 10)
  };
};

export const showVaraSearchModal = () => {
  return {
    type: types.SHOW_VARA_SEARCH_MODAL
  };
};

export const hideVaraSearchModal = () => {
  return {
    type: types.HIDE_VARA_SEARCH_MODAL
  };
};

export const showVaraResultSelectionModal = () => {
  return {
    type: types.SHOW_VARA_RESULT_SELECTION_MODAL
  };
};

export const hideVaraResultSelectionModal = () => {
  return {
    type: types.HIDE_VARA_RESULT_SELECTION_MODAL
  };
};

const varaSearchRequest = (name = '', strength = '') => {
  return {
    type: types.VARA_GET_SUGGESTIONS_REQUEST,
    payload: {
      name,
      strength
    }
  };
};

const varaSearchSuccess = (response) => {
  return {
    type: types.VARA_GET_SUGGESTIONS_SUCCESS,
    payload: response
  };
};

const varaSearchError = (error) => {
  return {
    type: types.VARA_GET_SUGGESTIONS_ERROR,
    payload: error
  };
};

export const varaSearch = (jwtToken, name, strength = '') => {
  return (dispatch) => {
    dispatch(varaSearchRequest(name, strength));

    api
      .searchMedications(jwtToken, `name=="${name.trim()}*";strength=="${strength.trim()}*"`)
      .then((response) => {
        dispatch(varaSearchSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(varaSearchError(error));
      });
  };
};

export const selectVaraSearchResult = (searchResultIndex) => {
  return {
    type: types.SELECT_VARA_SEARCH_RESULT,
    payload: searchResultIndex
  };
};

export const selectVaraImportType = (e) => {
  return {
    type: types.SELECT_VARA_IMPORT_TYPE,
    payload: e.target.value
  };
};

export const importVaraSearchResult = () => {
  return {
    type: types.IMPORT_VARA_SEARCH_RESULT
  };
};

export const toggleVaraBypassInfo = () => {
  return {
    type: types.TOGGLE_VARA_BYPASS_INFO
  };
};

export const continueWithoutVaraImport = () => {
  return {
    type: types.CONTINUE_WITHOUT_VARA_IMPORT
  };
};

export const setVaraBypassReasonType = (e) => {
  return {
    type: types.SET_VARA_BYPASS_REASON,
    payload: e.target.value
  };
};

export const setVaraBypassFreeTextReason = (e) => {
  return {
    type: types.SET_VARA_BYPASS_REASON_TEXT,
    payload: e.target.value
  };
};

export const addMedicationManually = () => {
  return {
    type: types.ADD_MEDICATION_MANUALLY
  };
};

const addMedicationRequest = () => {
  return {
    type: types.ADD_MEDICATION_REQUEST
  };
};

const addMedicationSuccess = (response) => {
  return {
    type: types.ADD_MEDICATION_SUCCESS,
    payload: response
  };
};

const addMedicationError = (error) => {
  return {
    type: types.ADD_MEDICATION_ERROR,
    payload: error
  };
};

export const addMedication = (jwtToken, memberGuid, medication) => {
  return (dispatch) => {
    dispatch(addMedicationRequest());

    api
      .addMedication(jwtToken, memberGuid, medication)
      .then((response) => {
        dispatch(addMedicationSuccess(response));
        dispatch(showNotification(I18n.t('notification.medication_added.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addMedicationError(error));
        dispatch(showNotification(I18n.t('notification.medication_added.error'), 'error'));
      });
  };
};

export const viewMedication = (medication) => {
  return (dispatch) => {
    return () => {
      dispatch({
        type: types.VIEW_MEDICATION,
        payload: medication
      });
    };
  };
};

export const showMedicationModal = () => {
  return {
    type: types.SHOW_MEDICATION_MODAL
  };
};

export const hideMedicationModal = () => {
  return {
    type: types.HIDE_MEDICATION_MODAL
  };
};

const cancelMedicationRequest = () => {
  return {
    type: types.CANCEL_MEDICATION_REQUEST
  };
};

const cancelMedicationSuccess = (response, medication, user) => {
  return {
    type: types.CANCEL_MEDICATION_SUCCESS,
    payload: {
      response,
      medication,
      user
    }
  };
};

const cancelMedicationError = (error, medicationId) => {
  return {
    type: types.CANCEL_MEDICATION_ERROR,
    payload: {
      error,
      medicationId
    }
  };
};

export const cancelMedication = (jwtToken, memberGuid, medication, user) => {
  return (dispatch) => {
    dispatch(cancelMedicationRequest());

    api
      .editMedication(jwtToken, memberGuid, medication.id, null, 'stop')
      .then((response) => {
        dispatch(cancelMedicationSuccess(response, medication, user));
        dispatch(showNotification(I18n.t('notification.medication_canceled.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        if (error.status === HTTP_STATUS_CODES.CONFLICT) {
          dispatch(cancelMedicationError(error, medication.id));
        } else {
          dispatch(showNotification(I18n.t('notification.medication_canceled.error'), 'error'));
          dispatch(cancelMedicationError(error));
        }
      });
  };
};

const invalidateMedicationRequest = (medicationId) => {
  return {
    type: types.INVALIDATE_MEDICATION_REQUEST,
    medicationId
  };
};

const invalidateMedicationSuccess = (response, medicationId, user) => {
  return {
    type: types.INVALIDATE_MEDICATION_SUCCESS,
    payload: {
      response,
      medicationId,
      user
    }
  };
};

const invalidateMedicationError = (error, medicationId) => {
  return {
    type: types.INVALIDATE_MEDICATION_ERROR,
    payload: {
      error,
      medicationId
    }
  };
};

export const invalidateMedication = (jwtToken, memberGuid, medicationId, user) => {
  return (dispatch) => {
    dispatch(invalidateMedicationRequest(medicationId));

    api
      .editMedication(jwtToken, memberGuid, medicationId, null, 'invalidate')
      .then((response) => {
        dispatch(invalidateMedicationSuccess(response, medicationId, user));
        dispatch(showNotification(I18n.t('notification.medication_invalidated.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(showNotification(I18n.t('notification.medication_invalidated.error'), 'error'));
        dispatch(invalidateMedicationError(error, medicationId));
      });
  };
};

const changeMedicationPropertyRequest = () => {
  return {
    type: types.CHANGE_MEDICATION_PROPERTY_REQUEST
  };
};

const changeMedicationPropertySuccess = (
  response,
  medicationId,
  changeType,
  changedProperty,
  previousPropertyValue,
  newMedicationBase,
  whenNeeded,
  user
) => {
  return {
    type: types.CHANGE_MEDICATION_PROPERTY_SUCCESS,
    payload: {
      response,
      medicationId,
      changeType,
      changedProperty,
      previousPropertyValue,
      newMedicationBase,
      whenNeeded,
      user
    }
  };
};

const changeMedicationPropertyError = (error) => {
  return {
    type: types.CHANGE_MEDICATION_PROPERTY_ERROR,
    payload: error
  };
};

export const changeMedicationProperty = (
  jwtToken,
  memberGuid,
  medicationId,
  newMedication,
  changeType,
  changedProperty,
  previousPropertyValue,
  whenNeeded,
  user
) => {
  return (dispatch) => {
    dispatch(changeMedicationPropertyRequest());

    api
      .editMedication(jwtToken, memberGuid, medicationId, newMedication, changeType)
      .then((response) => {
        dispatch(
          changeMedicationPropertySuccess(
            response,
            medicationId,
            changeType,
            changedProperty,
            previousPropertyValue,
            newMedication,
            whenNeeded,
            user
          )
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(changeMedicationPropertyError(error));
        dispatch(showNotification(I18n.t(`notification.medication_${changedProperty}_change.error`), 'error'));
      });
  };
};

export const showChangeMedicationPropertyModal = (config) => {
  return {
    type: types.SHOW_CHANGE_MEDICATION_PROPERTY_MODAL,
    payload: config
  };
};

export const hideChangeMedicationPropertyModal = () => {
  return {
    type: types.HIDE_CHANGE_MEDICATION_PROPERTY_MODAL
  };
};

export const dismissCancellationErrorMessage = () => {
  return {
    type: types.DISMISS_CANCELLATION_ERROR_MESSAGE
  };
};

export const clearMedications = () => {
  return {
    type: types.CLEAR_MEDICATIONS
  };
};

export const changeTab = (tabName) => {
  return {
    type: types.CHANGE_TAB,
    payload: tabName
  };
};

/**
 * Events
 */

export const toggleMemberEventList = () => {
  return {
    type: types.TOGGLE_MEMBER_EVENT_LIST
  };
};

const getEventsRequest = () => {
  return {
    type: types.GET_EVENTS_REQUEST
  };
};

const getEventsSuccess = (response, currentUserGuid, isNurse, fetchAllEvents) => {
  return {
    type: types.GET_EVENTS_SUCCESS,
    payload: response,
    currentUserGuid,
    isNurse,
    fetchAllEvents
  };
};

const getEventsError = (error) => {
  return {
    type: types.GET_EVENTS_ERROR,
    payload: error
  };
};

export const getEvents = (jwtToken, currentUserGuid, isNurse, fetchAllEvents) => {
  return (dispatch) => {
    dispatch(getEventsRequest());

    api
      .getEvents(jwtToken, !fetchAllEvents ? currentUserGuid : null)
      .then((response) => {
        dispatch(getEventsSuccess(response, currentUserGuid, isNurse, fetchAllEvents));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getEventsError(error));
      });
  };
};

const getPrioritizedEventsRequest = () => {
  return {
    type: types.GET_PRIORITIZED_EVENTS_REQUEST
  };
};

const getPrioritizedEventsSuccess = (response, currentUserGuid) => {
  return {
    type: types.GET_PRIORITIZED_EVENTS_SUCCESS,
    payload: response,
    currentUserGuid
  };
};

const getPrioritizedEventsError = (error) => {
  return {
    type: types.GET_PRIORITIZED_EVENTS_ERROR,
    payload: error
  };
};

export const getPrioritizedEvents = (jwtToken, caregiverGuid, limit) => {
  return (dispatch) => {
    dispatch(getPrioritizedEventsRequest());

    api
      .getPrioritizedEvents(jwtToken, caregiverGuid, limit)
      .then((response) => {
        dispatch(getPrioritizedEventsSuccess(response, caregiverGuid));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getPrioritizedEventsError(error));
      });
  };
};

const getActiveConsultationsRequest = () => {
  return {
    type: types.GET_ACTIVE_CONSULTATIONS_REQUEST
  };
};

const getActiveConsultationsSuccess = (response, currentUserGuid) => {
  return {
    type: types.GET_ACTIVE_CONSULTATIONS_SUCCESS,
    payload: response,
    currentUserGuid
  };
};

const getActiveConsultationsError = (error) => {
  return {
    type: types.GET_ACTIVE_CONSULTATIONS_ERROR,
    payload: error
  };
};

export const getActiveConsultations = (jwtToken, caregiverGuid, limit) => {
  return (dispatch) => {
    dispatch(getActiveConsultationsRequest());

    api
      .getActiveConsultations(jwtToken, caregiverGuid, limit)
      .then((response) => {
        dispatch(getActiveConsultationsSuccess(response, caregiverGuid));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getActiveConsultationsError(error));
      });
  };
};

const getUnhandledEventsRequest = () => {
  return {
    type: types.GET_UNHANDLED_EVENTS_REQUEST
  };
};

const getUnhandledEventsSuccess = (response) => {
  return {
    type: types.GET_UNHANDLED_EVENTS_SUCCESS,
    payload: response
  };
};

const getUnhandledEventsError = (error) => {
  return {
    type: types.GET_UNHANDLED_EVENTS_ERROR,
    payload: error
  };
};

export const getUnhandledEvents = (jwtToken) => {
  return (dispatch) => {
    dispatch(getUnhandledEventsRequest());

    api
      .getUnhandledEvents(jwtToken)
      .then((response) => {
        dispatch(getUnhandledEventsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getUnhandledEventsError(error));
      });
  };
};

export const sortEvents = (sortBy) => {
  return {
    type: types.SORT_EVENTS,
    sortBy
  };
};

export const sortUnhandledEvents = (sortBy) => {
  return {
    type: types.SORT_UNHANDLED_EVENTS,
    sortBy
  };
};

export const handleEventRequest = () => {
  return {
    type: types.HANDLE_EVENT_REQUEST
  };
};

export const handleEventSuccess = (response) => {
  return {
    type: types.HANDLE_EVENT_SUCCESS,
    payload: response
  };
};

export const handleEventError = (error) => {
  return {
    type: types.HANDLE_EVENT_ERROR,
    payload: error
  };
};

const getMemberEventHistoryRequest = () => {
  return {
    type: types.GET_MEMBER_EVENT_HISTORY_REQUEST
  };
};

const getMemberEventHistorySuccess = (response) => {
  return {
    type: types.GET_MEMBER_EVENT_HISTORY_SUCCESS,
    payload: response
  };
};

const getMemberEventHistoryError = (error) => {
  return {
    type: types.GET_MEMBER_EVENT_HISTORY_ERROR,
    payload: error
  };
};

export const getMemberEventHistory = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberEventHistoryRequest());

    api
      .getEventHistoryForMember(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberEventHistorySuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberEventHistoryError(error));
      });
  };
};

const getMemberActiveEventsRequest = () => {
  return {
    type: types.GET_MEMBER_ACTIVE_EVENTS_REQUEST
  };
};

const getMemberActiveEventsSuccess = (response) => {
  return {
    type: types.GET_MEMBER_ACTIVE_EVENTS_SUCCESS,
    payload: response
  };
};

const getMemberActiveEventsError = (error) => {
  return {
    type: types.GET_MEMBER_ACTIVE_EVENTS_ERROR,
    payload: error
  };
};

export const getMemberActiveEvents = (jwtToken, memberGuid, caregiverGuid) => {
  return (dispatch) => {
    dispatch(getMemberActiveEventsRequest());

    api
      .getActiveEventsForMember(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberActiveEventsSuccess(response));

        if (response.member.caregivers) {
          const caregiverIds = response.member.caregivers.map((caregiver) => caregiver.caregiver.guid);
          if (caregiverIds.includes(caregiverGuid)) {
            const storedVisitedPatients = loadState(INBOX_VISITED_PATIENTS_KEY, true) || {};
            storedVisitedPatients[memberGuid] = response.events.map((event) => event.id);
            saveState(storedVisitedPatients, INBOX_VISITED_PATIENTS_KEY);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberActiveEventsError(error));
      });
  };
};

export const clearMemberEventHistory = () => {
  return {
    type: types.CLEAR_MEMBER_EVENT_HISTORY
  };
};

export const clearMemberActiveEvents = () => {
  return {
    type: types.CLEAR_MEMBER_ACTIVE_EVENTS
  };
};

const getHandledEventsRequest = () => {
  return {
    type: types.GET_HANDLED_EVENTS_REQUEST
  };
};

const getHandledEventsSuccess = (response, currentUserGuid, isNurse, fetchAllEvents) => {
  return {
    type: types.GET_HANDLED_EVENTS_SUCCESS,
    payload: response,
    currentUserGuid,
    isNurse,
    fetchAllEvents
  };
};

const getHandledEventsError = (error) => {
  return {
    type: types.GET_HANDLED_EVENTS_ERROR,
    payload: error
  };
};

export const getHandledEvents = (jwtToken, currentUserGuid, isNurse, fetchAllEvents) => {
  return (dispatch) => {
    dispatch(getHandledEventsRequest());

    api
      .getHandledEvents(jwtToken, !fetchAllEvents ? currentUserGuid : null)
      .then((response) => {
        dispatch(getHandledEventsSuccess(response, currentUserGuid, isNurse, fetchAllEvents));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getHandledEventsError(error));
      });
  };
};

const scheduleEventRequest = () => {
  return {
    type: types.SCHEDULE_EVENT_REQUEST
  };
};

const scheduleEventSuccess = (response) => {
  return {
    type: types.SCHEDULE_EVENT_SUCCESS,
    payload: response
  };
};

const scheduleEventError = (error) => {
  return {
    type: types.SCHEDULE_EVENT_ERROR,
    payload: error
  };
};

export const scheduleEvent = (jwtToken, timestamp, comment, memberGuid, eventType) => {
  return (dispatch) => {
    dispatch(scheduleEventRequest());

    api
      .scheduleEvent(jwtToken, timestamp, comment, memberGuid, eventType)
      .then((response) => {
        dispatch(scheduleEventSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(scheduleEventError(error));
      });
  };
};

export const toggleShowOwnEvents = () => {
  return {
    type: types.TOGGLE_SHOW_OWN_EVENTS
  };
};

const snoozeEventRequest = () => {
  return {
    type: types.SNOOZE_EVENT_REQUEST
  };
};

const snoozeEventSuccess = (response) => {
  return {
    type: types.SNOOZE_EVENT_SUCCESS,
    payload: response
  };
};

const snoozeEventError = (error) => {
  return {
    type: types.SNOOZE_EVENT_ERROR,
    payload: error
  };
};

export const snoozeEvent = (jwtToken, eventId, eventUpdates) => {
  return (dispatch) => {
    dispatch(snoozeEventRequest());

    api
      .handleEvent(jwtToken, eventId, eventUpdates)
      .then((response) => {
        dispatch(snoozeEventSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(snoozeEventError(error));
        dispatch(showNotification(I18n.t('notification.snooze_errand.error'), 'error'));
      });
  };
};

/**
 * Statistics
 */

const getStatisticsRequest = () => {
  return {
    type: types.MEMBER_STATS_REQUEST
  };
};

const getStatisticsSuccess = (response) => {
  return {
    type: types.MEMBER_STATS_SUCCESS,
    payload: response
  };
};

const getStatisticsError = (response) => {
  return {
    type: types.MEMBER_STATS_ERROR,
    payload: response
  };
};

export const getBloodPressureStats = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getStatisticsRequest());

    api
      .getBloodPressureStats(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getStatisticsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getStatisticsError(error));
      });
  };
};

const setPeriodSize = (periodSize) => {
  return {
    type: types.SET_STATS_PERIOD_SIZE,
    payload: periodSize
  };
};

export const getStatistics = (jwtToken, memberGuid, startPeriod, periodSize) => {
  return (dispatch) => {
    dispatch(getStatisticsRequest());

    return api
      .getBloodPressureStats(jwtToken, memberGuid, startPeriod, periodSize)
      .then((response) => {
        dispatch(getStatisticsSuccess(response));
        dispatch(setPeriodSize(periodSize));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getStatisticsError(error));
      });
  };
};

/**
 * Referrals
 */

const getReferralSessionRequest = () => {
  return {
    type: types.GET_REFERRAL_SESSION_REQUEST
  };
};

const getReferralSessionSuccess = (response) => {
  return {
    type: types.GET_REFERRAL_SESSION_SUCCESS,
    payload: response
  };
};

const getReferralSessionError = (error) => {
  return {
    type: types.GET_REFERRAL_SESSION_ERROR,
    payload: error
  };
};

export const getReferralSession = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getReferralSessionRequest());

    return api
      .getReferralSession(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getReferralSessionSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getReferralSessionError(error));
      });
  };
};

export const clearReferralSession = () => {
  return {
    type: types.CLEAR_REFERRAL_SESSION
  };
};

const getAllReferralsRequest = () => {
  return {
    type: types.GET_ALL_REFERRALS_REQUEST
  };
};

const getAllReferralsSuccess = (response) => {
  return {
    type: types.GET_ALL_REFERRALS_SUCCESS,
    payload: response
  };
};

const getAllReferralsError = (error) => {
  return {
    type: types.GET_ALL_REFERRALS_ERROR,
    payload: error
  };
};

export const getAllReferrals = (jwtToken) => {
  return (dispatch) => {
    dispatch(getAllReferralsRequest());

    return api
      .getAllReferrals(jwtToken)
      .then((response) => {
        dispatch(getAllReferralsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getAllReferralsError(error));
      });
  };
};

const getAllLabResultsRequest = () => {
  return {
    type: types.GET_ALL_LAB_RESULTS_REQUEST
  };
};

const getAllLabResultsSuccess = (response) => {
  return {
    type: types.GET_ALL_LAB_RESULTS_SUCCESS,
    payload: response
  };
};

const getAllLabResultsError = (error) => {
  return {
    type: types.GET_ALL_LAB_RESULTS_ERROR,
    payload: error
  };
};

export const getAllLabResults = (jwtToken) => {
  return (dispatch) => {
    dispatch(getAllLabResultsRequest());

    return api
      .getAllLabResults(jwtToken)
      .then((response) => {
        dispatch(getAllLabResultsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getAllLabResultsError(error));
      });
  };
};

const getReferralsRequest = () => {
  return {
    type: types.GET_REFERRALS_REQUEST
  };
};

const getReferralsSuccess = (response) => {
  return {
    type: types.GET_REFERRALS_SUCCESS,
    payload: response
  };
};

const getReferralsError = (error) => {
  return {
    type: types.GET_REFERRALS_ERROR,
    payload: error
  };
};

export const getReferrals = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getReferralsRequest());

    return api
      .getReferrals(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getReferralsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getReferralsError(error));
      });
  };
};

/**
 * Notes
 */

const getNotesRequest = () => {
  return {
    type: types.GET_NOTES_REQUEST
  };
};

const getNotesSuccess = (response) => {
  return {
    type: types.GET_NOTES_SUCCESS,
    payload: response
  };
};

const getNotesError = (error) => {
  return {
    type: types.GET_NOTES_ERROR,
    payload: error
  };
};

export const getNotes = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getNotesRequest());

    api
      .getMemberNotes(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getNotesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getNotesError(error));
      });
  };
};

export const clearNotes = () => {
  return {
    type: types.CLEAR_NOTES
  };
};

const addNoteRequest = () => {
  return {
    type: types.ADD_NOTE_REQUEST
  };
};

const addNoteSuccess = (response) => {
  return {
    type: types.ADD_NOTE_SUCCESS,
    payload: response
  };
};

const addNoteError = (error) => {
  return {
    type: types.ADD_NOTE_ERROR,
    payload: error
  };
};

export const addNote = (jwtToken, memberGuid, noteType, text) => {
  return (dispatch) => {
    dispatch(addNoteRequest());

    api
      .addMemberNote(jwtToken, memberGuid, { noteType, text })
      .then((response) => {
        dispatch(addNoteSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addNoteError(error));
      });
  };
};

const addDayNoteRequest = () => {
  return {
    type: types.ADD_DAY_NOTE_REQUEST
  };
};

const addDayNoteSuccess = (response) => {
  return {
    type: types.ADD_DAY_NOTE_SUCCESS,
    payload: response
  };
};

const addDayNoteError = (error) => {
  return {
    type: types.ADD_DAY_NOTE_ERROR,
    payload: error
  };
};

export const addDayNote = (jwtToken, memberGuid, text) => {
  return (dispatch) => {
    dispatch(addDayNoteRequest());

    api
      .addMemberNote(jwtToken, memberGuid, { noteType: 'daynote', text })
      .then((response) => {
        dispatch(addDayNoteSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addDayNoteError(error));
      });
  };
};

export const showDayNoteModal = () => {
  return {
    type: types.SHOW_DAY_NOTE_MODAL
  };
};

export const hideDayNoteModal = () => {
  return {
    type: types.HIDE_DAY_NOTE_MODAL
  };
};

export const updateDayNoteContent = (event) => {
  return {
    type: types.UPDATE_DAY_NOTE_CONTENT,
    payload: event.target.value
  };
};

export const toggleShowOnlyOwnNotes = () => {
  return {
    type: types.TOGGLE_SHOW_ONLY_OWN_NOTES
  };
};

const getMemberCommentRequest = () => {
  return {
    type: types.GET_MEMBER_COMMENT_REQUEST
  };
};

const getMemberCommentSuccess = (response) => {
  return {
    type: types.GET_MEMBER_COMMENT_SUCCESS,
    payload: response
  };
};

const getMemberCommentError = (error) => {
  return {
    type: types.GET_MEMBER_COMMENT_ERROR,
    payload: error
  };
};

export const getMemberComment = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberCommentRequest());

    api
      .getMemberComment(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberCommentSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberCommentError(error));
        if (error.status !== HTTP_STATUS_CODES.NOT_FOUND) {
          dispatch(showNotification(I18n.t('notification.fetch_patient_comment.error'), 'error'));
        }
      });
  };
};

const saveMemberCommentRequest = () => {
  return {
    type: types.SAVE_MEMBER_COMMENT_REQUEST
  };
};

const saveMemberCommentSuccess = (text) => {
  return {
    type: types.SAVE_MEMBER_COMMENT_SUCCESS,
    payload: {
      text
    }
  };
};

const saveMemberCommentError = (error) => {
  return {
    type: types.SAVE_MEMBER_COMMENT_ERROR,
    payload: error
  };
};

export const saveMemberComment = (jwtToken, memberGuid, text, callback) => {
  return (dispatch) => {
    dispatch(saveMemberCommentRequest());

    api
      .saveMemberComment(jwtToken, memberGuid, text)
      .then(() => {
        dispatch(saveMemberCommentSuccess(text));
        callback();
      })
      .catch((error) => {
        console.log(error);
        dispatch(saveMemberCommentError(error));
        dispatch(showNotification(I18n.t('notification.save_patient_comment.error'), 'error'));
      });
  };
};

export const updateMemberCommentText = (text) => {
  return {
    type: types.UPDATE_MEMBER_COMMENT_TEXT,
    text
  };
};

export const toggleMemberCommentField = () => {
  return {
    type: types.TOGGLE_MEMBER_COMMENT_FIELD
  };
};

/**
 * Decision support
 */

const saveDssStart = () => {
  return {
    type: types.SAVE_DSS
  };
};

const saveDssSuccess = () => {
  return {
    type: types.SAVE_DSS_SUCCESS
  };
};

const saveDssError = () => {
  return {
    type: types.SAVE_DSS_ERROR
  };
};

const saveFinalNoteRequest = () => {
  return {
    type: types.SAVE_FINAL_NOTE_REQUEST
  };
};

const saveFinalNoteSuccess = () => {
  return {
    type: types.SAVE_FINAL_NOTE_SUCCESS
  };
};

const saveFinalNoteError = (error) => {
  return {
    type: types.SAVE_FINAL_NOTE_ERROR,
    payload: error
  };
};

export const createFinalNote = (jwtToken, memberGuid, finalNote) => {
  return (dispatch) => {
    dispatch(saveFinalNoteRequest());

    api
      .addMemberNote(jwtToken, memberGuid, finalNote)
      .then((response) => {
        dispatch(saveFinalNoteSuccess());
        dispatch(addNoteSuccess(response));
        dispatch(showNotification(I18n.t('notification.final_note_created.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(saveFinalNoteError(error));
      });
  };
};

export const saveFinalNote = (jwtToken, memberGuid, finalNote) => {
  return (dispatch) => {
    dispatch(addNoteRequest());
    api
      .addMemberNote(jwtToken, memberGuid, finalNote)
      .then((response) => {
        dispatch(addNoteSuccess(response));
        dispatch(hideFinalNoteModal());
        dispatch(saveFinalNoteSuccess());
        dispatch(showNotification(I18n.t('notification.final_note_created.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideFinalNoteModal());
        dispatch(addNoteError());
        dispatch(showNotification(I18n.t('notification.final_note_created.error'), 'error'));
      });
  };
};

export const updateFinalNoteProperty = ({ property, value, isManualTextChange }) => {
  return {
    type: types.UPDATE_FINAL_NOTE,
    payload: {
      property: property,
      value: value,
      isManualTextChange: isManualTextChange
    }
  };
};

export const updateAdditionalDiagnosisCodes = (code) => {
  return {
    type: types.UPDATE_ADDITIONAL_DIAGNOSIS_CODES,
    payload: code
  };
};

/**
 * Save blood pressure goals.
 * Save risk factor recommendations
 * Save a final note.
 * Sign the decision support system.
 */
export const saveDss = (
  jwtToken,
  memberGuid,
  goalSystolic,
  goalDiastolic,
  measureFrequencyWeekly,
  riskFactorRecommendations,
  finalNote,
  roleTransferDelay
) => {
  return (dispatch) => {
    dispatch(saveDssStart());
    dispatch(postBloodPressureGoalsRequest());

    api
      .postBloodPressureGoals(jwtToken, memberGuid, goalSystolic, goalDiastolic, measureFrequencyWeekly)
      .then((response) => {
        dispatch(postBloodPressureGoalsSuccess(response));
        dispatch(postRiskFactorRecommendationsRequest());
        return api.postRiskFactorRecommendations(jwtToken, memberGuid, riskFactorRecommendations);
      })
      .then((response) => {
        dispatch(postRiskFactorRecommendationsSuccess(response));
        dispatch(addNoteRequest());
        return api.addMemberNote(jwtToken, memberGuid, finalNote);
      })
      .then((response) => {
        if (finalNote) {
          dispatch(addNoteSuccess(response));
          dispatch(setActiveCaregiverRequest());
          return api.setActiveCaregiver(jwtToken, memberGuid, CAREGIVER_ROLE.NURSE, roleTransferDelay);
        }
        return Promise.resolve(null);
      })
      .then((response) => {
        if (response) {
          dispatch(setActiveCaregiverSuccess(response));
        }
        dispatch(signDssRequest());
        return api.signDss(jwtToken, memberGuid);
      })
      .then(() => {
        dispatch(signDssSuccess());
        dispatch(hideFinalNoteModal());
        dispatch(saveDssSuccess());
        dispatch(showNotification(I18n.t('decision_support_view.sign_report_success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(saveDssError()); // Todo: better error handling. Any of the above might have failed.
        dispatch(hideFinalNoteModal());
        dispatch(showNotification(I18n.t('decision_support_view.sign_report_error'), 'error'));
      });
  };
};

const setActiveCaregiverRequest = () => {
  return {
    type: types.SET_ACTIVE_CAREGIVER_REQUEST
  };
};

const setActiveCaregiverSuccess = () => {
  return {
    type: types.SET_ACTIVE_CAREGIVER_SUCCESS
  };
};

export const showFinalNoteModal = () => {
  return {
    type: types.SHOW_FINAL_NOTE_MODAL
  };
};

export const hideFinalNoteModal = () => {
  return {
    type: types.HIDE_FINAL_NOTE_MODAL
  };
};

export const showFinalNoteConfirmationModal = () => {
  return {
    type: types.SHOW_FINAL_NOTE_CONFIRMATION_MODAL
  };
};

export const hideFinalNoteConfirmationModal = () => {
  return {
    type: types.HIDE_FINAL_NOTE_CONFIRMATION_MODAL
  };
};

const getDecisionSupportSummaryRequest = () => {
  return {
    type: types.GET_DECISION_SUPPORT_SUMMARY_REQUEST
  };
};

const getDecisionSupportSummarySuccess = (response) => {
  return {
    type: types.GET_DECISION_SUPPORT_SUMMARY_SUCCESS,
    payload: response
  };
};

const getDecisionSupportSummaryError = (error) => {
  return {
    type: types.GET_DECISION_SUPPORT_SUMMARY_ERROR,
    payload: error
  };
};

export const getDecisionSupportSummary = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getDecisionSupportSummaryRequest());

    api
      .getMemberDecisionSupportSummary(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getDecisionSupportSummarySuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getDecisionSupportSummaryError(error));
      });
  };
};

const addDssOverviewItemCommentRequest = () => {
  return {
    type: types.ADD_DSS_OVERVIEW_ITEM_COMMENT_REQUEST
  };
};

const addDssOverviewItemCommentSuccess = (response) => {
  return {
    type: types.ADD_DSS_OVERVIEW_ITEM_COMMENT_SUCCESS,
    payload: response
  };
};

const addDssOverviewItemCommentError = (error) => {
  return {
    type: types.ADD_DSS_OVERVIEW_ITEM_COMMENT_ERROR,
    payload: error
  };
};

export const addDssItemComment = (jwtToken, memberGuid, itemId, comment, cb) => {
  return (dispatch) => {
    dispatch(addDssOverviewItemCommentRequest());

    api
      .addDssItemComment(jwtToken, memberGuid, itemId, comment)
      .then((response) => {
        dispatch(addDssOverviewItemCommentSuccess(response));
        cb && cb();
      })
      .catch((error) => {
        console.log(error);
        dispatch(addDssOverviewItemCommentError(error));
        cb && cb();
      });
  };
};

const setDssRelevantValuesRequest = () => {
  return {
    type: types.SET_DSS_RELEVANT_VALUES_REQUEST
  };
};

const setDssRelevantValuesSuccess = (response) => {
  return {
    type: types.SET_DSS_RELEVANT_VALUES_SUCCESS,
    payload: response
  };
};

const setDssRelevantValuesError = (error) => {
  return {
    type: types.SET_DSS_RELEVANT_VALUES_ERROR,
    payload: error
  };
};

export const setDssRelevantValues = (jwtToken, memberGuid, itemId, itemValues, cb) => {
  return (dispatch) => {
    dispatch(setDssRelevantValuesRequest());

    api
      .setDssRelevantValues(jwtToken, memberGuid, itemId, itemValues)
      .then((response) => {
        dispatch(setDssRelevantValuesSuccess(response));
        cb && cb();
      })
      .catch((error) => {
        console.log(error);
        dispatch(setDssRelevantValuesError(error));
        cb && cb();
      });
  };
};

const getDssItemHistoryRequest = () => {
  return {
    type: types.GET_DSS_ITEM_HISTORY_REQUEST
  };
};

const getDssItemHistorySuccess = (response) => {
  return {
    type: types.GET_DSS_ITEM_HISTORY_SUCCESS,
    payload: response
  };
};

const getDssItemHistoryError = (error) => {
  return {
    type: types.GET_DSS_ITEM_HISTORY_ERROR,
    payload: error
  };
};

export const getDssItemHistory = (jwtToken, memberGuid, itemId) => {
  return (dispatch) => {
    dispatch(getDssItemHistoryRequest());

    api
      .getDssItemHistory(jwtToken, memberGuid, itemId)
      .then((response) => {
        dispatch(getDssItemHistorySuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getDssItemHistoryError(error));
      });
  };
};

const getDssAllergyItemRequest = () => {
  return {
    type: types.GET_DSS_ALLERGY_ITEM_REQUEST
  };
};

const getDssAllergyItemSuccess = (response) => {
  return {
    type: types.GET_DSS_ALLERGY_ITEM_SUCCESS,
    payload: response
  };
};

const getDssAllergyItemError = (error) => {
  return {
    type: types.GET_DSS_ALLERGY_ITEM_ERROR,
    payload: error
  };
};

export const getDssAllergyItem = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getDssAllergyItemRequest());

    api
      .getDssItemHistory(jwtToken, memberGuid, ALLERGY_DSS_ITEM_ID)
      .then((response) => {
        dispatch(getDssAllergyItemSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getDssAllergyItemError(error));
      });
  };
};

export const setDssCurrentItemNumber = (itemId) => {
  return {
    type: types.SET_DSS_CURRENT_ITEM_NUMBER,
    payload: itemId
  };
};

export const setDssRelevantValuesToUpdate = (relevantValues) => {
  return {
    type: types.SET_DSS_RELEVANT_VALUES_TO_UPDATE,
    payload: relevantValues
  };
};

export const setDssItemComment = (itemComment) => {
  return {
    type: types.SET_DSS_ITEM_COMMENT,
    payload: itemComment
  };
};

export const clearDssItemEditValues = () => {
  return {
    type: types.CLEAR_DSS_ITEM_EDIT_VALUES
  };
};

const getMeasurementStatusRequest = () => {
  return {
    type: types.GET_DSS_MEASUREMENT_STATUS_REQUEST
  };
};

const getMeasurementStatusSuccess = (response) => {
  return {
    type: types.GET_DSS_MEASUREMENT_STATUS_SUCCESS,
    payload: response
  };
};

const getMeasurementStatusError = (error) => {
  return {
    type: types.GET_DSS_MEASUREMENT_STATUS_ERROR,
    payload: error
  };
};

export const getMeasurementStatus = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMeasurementStatusRequest());

    api
      .getMemberMeasurementStatus(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMeasurementStatusSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMeasurementStatusError(error));
      });
  };
};

const getBloodPressureSummaryRequest = () => {
  return {
    type: types.GET_DSS_BLOOD_PRESSURE_SUMMARY_REQUEST
  };
};

const getBloodPressureSummarySuccess = (response) => {
  return {
    type: types.GET_DSS_BLOOD_PRESSURE_SUMMARY_SUCCESS,
    payload: response
  };
};

const getBloodPressureSummaryError = (error) => {
  return {
    type: types.GET_DSS_BLOOD_PRESSURE_SUMMARY_ERROR,
    payload: error
  };
};

export const getBloodPressureSummary = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getBloodPressureSummaryRequest());

    api
      .getMemberBloodPressureSummary(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getBloodPressureSummarySuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getBloodPressureSummaryError(error));
      });
  };
};

export const clearDssData = () => {
  return {
    type: types.CLEAR_DSS_DATA
  };
};

const getDssLabResultsRequest = () => {
  return {
    type: types.GET_LAB_RESULTS_REQUEST
  };
};

const getDssLabResultsSuccess = (response, locale) => {
  return {
    type: types.GET_LAB_RESULTS_SUCCESS,
    payload: response,
    windowWidth: window.innerWidth,
    locale
  };
};

const getDssLabResultsError = (error) => {
  return {
    type: types.GET_LAB_RESULTS_ERROR,
    payload: error
  };
};

export const getDssLabResults = (jwtToken, memberGuid, locale) => {
  return (dispatch) => {
    dispatch(getDssLabResultsRequest());

    api
      .getMemberLabResults(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getDssLabResultsSuccess(response, locale));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getDssLabResultsError(error));
      });
  };
};

export const viewOlderLabResults = () => {
  return {
    type: types.VIEW_OLDER_LAB_RESULTS
  };
};

export const viewNewerLabResults = () => {
  return {
    type: types.VIEW_NEWER_LAB_RESULTS
  };
};

export const toggleShowLabEvaluation = () => {
  return {
    type: types.TOGGLE_SHOW_LAB_EVALUATION,
    windowWidth: window.innerWidth
  };
};

const getBloodPressureTrendsRequest = () => {
  return {
    type: types.GET_BLOOD_PRESSURE_TREND_REQUEST
  };
};

const getBloodPressureTrendsSuccess = (response) => {
  return {
    type: types.GET_BLOOD_PRESSURE_TREND_SUCCESS,
    payload: response
  };
};

const getBloodPressureTrendsError = (error) => {
  return {
    type: types.GET_BLOOD_PRESSURE_TREND_ERROR,
    payload: error
  };
};

const setTrendPeriodSize = (periodSize) => {
  return {
    type: types.SET_TREND_PERIOD_SIZE,
    payload: periodSize
  };
};

export const getBloodPressureTrends = (jwtToken, memberGuid, startPeriod, periodSize) => {
  return (dispatch) => {
    dispatch(getBloodPressureTrendsRequest());

    dispatch(setTrendPeriodSize(periodSize));
    api
      .getMemberBloodPressureTrends(jwtToken, memberGuid, startPeriod, periodSize)
      .then((response) => {
        dispatch(getBloodPressureTrendsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getBloodPressureTrendsError(error));
      });
  };
};

const getTwoWeekTrendRequest = () => {
  return {
    type: types.GET_TWO_WEEK_TREND_REQUEST
  };
};

const getTwoWeekTrendSuccess = (response) => {
  return {
    type: types.GET_TWO_WEEK_TREND_SUCCESS,
    payload: response
  };
};

const getTwoWeekTrendError = (error) => {
  return {
    type: types.GET_TWO_WEEK_TREND_ERROR,
    payload: error
  };
};

export const getTwoWeekTrend = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getTwoWeekTrendRequest());

    api
      .getMemberBloodPressureTrends(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getTwoWeekTrendSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getTwoWeekTrendError(error));
      });
  };
};

const getDssRiskProfileRequest = () => {
  return {
    type: types.GET_DSS_RISK_PROFILE_REQUEST
  };
};

const getDssRiskProfileSuccess = (response) => {
  return {
    type: types.GET_DSS_RISK_PROFILE_SUCCESS,
    payload: response
  };
};

const getDssRiskProfileError = (error) => {
  return {
    type: types.GET_DSS_RISK_PROFILE_ERROR,
    payload: error
  };
};

export const getDssRiskProfile = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getDssRiskProfileRequest());

    api
      .getDssRiskProfile(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getDssRiskProfileSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getDssRiskProfileError(error));
      });
  };
};

export const toggleRiskFactorRecommendation = (riskFactorId) => {
  return {
    type: types.TOGGLE_RISK_FACTOR_RECOMMENDATION,
    payload: riskFactorId
  };
};

export const postRiskFactorRecommendationsRequest = () => {
  return {
    type: types.POST_RISK_FACTOR_RECOMMENDATIONS_REQUEST
  };
};

export const postRiskFactorRecommendationsSuccess = (response) => {
  return {
    type: types.POST_RISK_FACTOR_RECOMMENDATIONS_SUCCESS,
    payload: response
  };
};

export const postRiskFactorRecommendationsError = (error) => {
  return {
    type: types.POST_RISK_FACTOR_RECOMMENDATIONS_ERROR,
    payload: error
  };
};

export const postRiskFactorRecommendations = (jwtToken, memberGuid, recommendations) => {
  return (dispatch) => {
    dispatch(postRiskFactorRecommendationsRequest());

    api
      .postRiskFactorRecommendations(jwtToken, memberGuid, recommendations)
      .then((response) => {
        dispatch(postRiskFactorRecommendationsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(postRiskFactorRecommendationsError(error));
      });
  };
};

const getObjectivesRequest = () => {
  return {
    type: types.GET_OBJECTIVES_REQUEST
  };
};

const getObjectivesSuccess = (response) => {
  return {
    type: types.GET_OBJECTIVES_SUCCESS,
    payload: response
  };
};

const getObjectivesError = (error) => {
  return {
    type: types.GET_OBJECTIVES_ERROR,
    payload: error
  };
};

export const getObjectives = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getObjectivesRequest());

    api
      .getObjectives(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getObjectivesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getObjectivesError(error));
      });
  };
};

const postBloodPressureGoalsRequest = () => {
  return {
    type: types.POST_BLOOD_PRESSURE_GOALS_REQUEST
  };
};

const postBloodPressureGoalsSuccess = (response) => {
  return {
    type: types.POST_BLOOD_PRESSURE_GOALS_SUCCESS,
    payload: response
  };
};

const postBloodPressureGoalsError = (error) => {
  return {
    type: types.POST_BLOOD_PRESSURE_GOALS_ERROR,
    payload: error
  };
};

export const postBloodPressureGoals = (jwtToken, memberGuid, goalSystolic, goalDiastolic, measureFrequencyWeekly) => {
  return (dispatch) => {
    dispatch(postBloodPressureGoalsRequest());

    api
      .postBloodPressureGoals(jwtToken, memberGuid, goalSystolic, goalDiastolic, measureFrequencyWeekly)
      .then((response) => {
        dispatch(postBloodPressureGoalsSuccess(response));
        dispatch(
          showNotification(I18n.t('decision_support_view.risk_profile_section.blood_pressure_goals_saved'), 'success')
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(postBloodPressureGoalsError(error));
        dispatch(
          showNotification(
            I18n.t('decision_support_view.risk_profile_section.blood_pressure_goals_save_error'),
            'error'
          )
        );
      });
  };
};

export const toggleTreatment = (treatmentId) => {
  return {
    type: types.DSS_TOGGLE_TREATMENT,
    payload: treatmentId
  };
};

const getDssStatusRequest = () => {
  return {
    type: types.GET_DSS_STATUS_REQUEST
  };
};

const getDssStatusSuccess = (response) => {
  return {
    type: types.GET_DSS_STATUS_SUCCESS,
    payload: response
  };
};

const getDssStatusError = (error) => {
  return {
    type: types.GET_DSS_STATUS_ERROR,
    payload: error
  };
};

export const getDssStatus = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getDssStatusRequest());

    api
      .getDssStatus(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getDssStatusSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getDssStatusError(error));
      });
  };
};

const deleteCheckupRequest = (checkupId) => {
  return {
    type: types.DELETE_MEMBER_CHECKUP_REQUEST,
    id: checkupId
  };
};

const deleteCheckupSuccess = (checkup, cancellationReason) => {
  return {
    type: types.DELETE_MEMBER_CHECKUP_SUCCESS,
    id: checkup.id,
    checkup,
    cancellationReason
  };
};

const deleteCheckupError = (error, checkupId) => {
  return {
    type: types.DELETE_MEMBER_CHECKUP_ERROR,
    payload: error,
    id: checkupId
  };
};

export const deleteCheckup = (jwtToken, memberGuid, checkup, cancellationReason, cb) => {
  return (dispatch) => {
    dispatch(deleteCheckupRequest(checkup.id));

    api
      .deleteCheckup(jwtToken, memberGuid, checkup.id, cancellationReason)
      .then(() => {
        dispatch(deleteCheckupSuccess(checkup, cancellationReason));
        cb && cb();
      })
      .catch((error) => {
        console.log(error);
        dispatch(deleteCheckupError(error, checkup.id));

        if (error.status === HTTP_STATUS_CODES.CONFLICT) {
          dispatch(showNotification(I18n.t('notification.delete_checkup_conflict.error'), 'error'));
        } else {
          dispatch(showNotification(I18n.t('notification.delete_checkup.error'), 'error'));
        }
      });
  };
};

const editCheckupRequest = () => {
  return {
    type: types.EDIT_MEMBER_CHECKUP_REQUEST
  };
};

export const editCheckupSuccess = (response) => {
  return {
    type: types.EDIT_MEMBER_CHECKUP_SUCCESS,
    payload: response
  };
};

const editCheckupError = (error) => {
  return {
    type: types.EDIT_MEMBER_CHECKUP_ERROR,
    payload: error
  };
};

export const editCheckup = (jwtToken, memberGuid, editedCheckup, cb) => {
  return (dispatch) => {
    dispatch(editCheckupRequest());

    api
      .editCheckup(jwtToken, memberGuid, editedCheckup)
      .then((response) => {
        dispatch(editCheckupSuccess(response));
        cb && cb();
      })
      .catch((error) => {
        console.log(error);
        dispatch(editCheckupError(error));
        dispatch(showNotification(I18n.t('notification.save_checkup.error'), 'error'));
      });
  };
};

export const showCancellationReasonModal = (selectedCheckup) => {
  return {
    type: types.SHOW_CANCELLATION_REASON_MODAL,
    selectedCheckup
  };
};

export const hideCancellationReasonModal = () => {
  return {
    type: types.HIDE_CANCELLATION_REASON_MODAL
  };
};

export const showEditCheckupModal = (selectedCheckup) => {
  return {
    type: types.SHOW_EDIT_CHECKUP_MODAL,
    selectedCheckup
  };
};

export const hideEditCheckupModal = () => {
  return {
    type: types.HIDE_EDIT_CHECKUP_MODAL
  };
};

export const setEditedCheckupDate = (date) => {
  return {
    type: types.SET_EDITED_CHECKUP_DATE,
    payload: date
  };
};

export const setCheckupReasonFreeText = (e) => {
  return {
    type: types.SET_REMINDER_REASON_FREE_TEXT,
    payload: e.target.value
  };
};

export const toggleEditedCheckupReason = (reasonId) => {
  return {
    type: types.TOGGLE_REMINDER_REASON,
    payload: reasonId
  };
};

export const showNewCheckupBooking = () => {
  return {
    type: types.SHOW_NEW_CHECKUP_BOOKING
  };
};

export const selectNewCheckupCaregiverType = (e) => {
  return {
    type: types.SELECT_NEW_CHECKUP_CAREGIVER_TYPE,
    payload: e.target.value
  };
};

export const selectNewCheckupCategory = (e) => {
  return {
    type: types.SELECT_NEW_CHECKUP_CATEGORY,
    payload: e.target.value
  };
};

export const selectNewCheckupType = (e) => {
  return {
    type: types.SELECT_NEW_CHECKUP_TYPE,
    payload: e.target.value
  };
};

export const setNewReminderReasonFreeText = (e) => {
  return {
    type: types.SET_NEW_REMINDER_FREE_TEXT_REASON,
    payload: e.target.value
  };
};

export const toggleNewReminderReason = (e) => {
  return {
    type: types.TOGGLE_NEW_REMINDER_REASON,
    payload: window.parseInt(e.target.value, 10)
  };
};

export const setNewCheckupDate = (date) => {
  return {
    type: types.SELECT_NEW_CHECKUP_DATE,
    payload: date
  };
};

export const includeFreeTextReason = () => {
  return {
    type: types.TOGGLE_INCLUDE_FREE_TEXT_REASON
  };
};

export const setDssGoalSystolic = (goalValue) => {
  return {
    type: types.SET_DSS_GOAL_SYSTOLIC,
    payload: goalValue
  };
};

export const setDssGoalDiastolic = (goalValue) => {
  return {
    type: types.SET_DSS_GOAL_DIASTOLIC,
    payload: goalValue
  };
};

export const setDssMeasurementFrequency = (frequency) => {
  return {
    type: types.DSS_SET_MEASUREMENT_FREQUENCY,
    payload: frequency
  };
};

const signDssRequest = () => {
  return {
    type: types.SIGN_DSS_REQUEST
  };
};

const signDssSuccess = () => {
  return {
    type: types.SIGN_DSS_SUCCESS
  };
};

const signDssError = (error) => {
  return {
    type: types.SIGN_DSS_ERROR,
    payload: error
  };
};

export const signDss = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(signDssRequest());

    api
      .signDss(jwtToken, memberGuid)
      .then((response) => {
        dispatch(signDssSuccess(response));
        dispatch(showNotification(I18n.t('decision_support_view.sign_report_success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(signDssError(error));
        // dispatch(showNotification(I18n.t('decision_support_view.sign_report_error'), 'error'));
      });
  };
};

const postMemberCheckupRequest = () => {
  return {
    type: types.POST_MEMBER_CHECKUP_REQUEST
  };
};

const postMemberCheckupSuccess = (response) => {
  return {
    type: types.POST_MEMBER_CHECKUP_SUCCESS,
    payload: response
  };
};

const postMemberCheckupError = (error) => {
  return {
    type: types.POST_MEMBER_CHECKUP_ERROR,
    payload: error
  };
};

export const postMemberCheckup = (jwtToken, memberGuid, checkup) => {
  return (dispatch) => {
    dispatch(postMemberCheckupRequest());
    api
      .createCheckup(jwtToken, memberGuid, checkup)
      .then((response) => {
        dispatch(postMemberCheckupSuccess(response));
        dispatch(
          showNotification(
            I18n.t(
              `notification.${checkup.type.toLowerCase().includes('reminder') ? 'reminder' : 'checkup'}_saved.success`,
              { date: checkup.checkupDate }
            ),
            'success'
          )
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(postMemberCheckupError(error));
        dispatch(
          showNotification(
            I18n.t(
              `notification.${checkup.type.toLowerCase().includes('reminder') ? 'reminder' : 'checkup'}_saved.error`
            ),
            'error'
          )
        );
      });
  };
};

const getCheckupCalendarRequest = () => {
  return {
    type: types.GET_CHECKUP_CALENDAR_REQUEST
  };
};

const getCheckupCalendarSuccess = (response) => {
  return {
    type: types.GET_CHECKUP_CALENDAR_SUCCESS,
    payload: response
  };
};

const getCheckupCalendarError = (error) => {
  return {
    type: types.GET_CHECKUP_CALENDAR_ERROR,
    payload: error
  };
};

export const getCheckupCalendar = (jwtToken) => {
  return (dispatch) => {
    dispatch(getCheckupCalendarRequest());
    api
      .getCheckupCalendar(jwtToken)
      .then((response) => {
        dispatch(getCheckupCalendarSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getCheckupCalendarError(error));
      });
  };
};

const getAnamnesisUpdateSummaryRequest = () => {
  return {
    type: types.GET_ANAMNESIS_UPDATE_SUMMARY_REQUEST
  };
};

const getAnamnesisUpdateSummarySuccess = (response) => {
  return {
    type: types.GET_ANAMNESIS_UPDATE_SUMMARY_SUCCESS,
    payload: response
  };
};

const getAnamnesisUpdateSummaryError = (error) => {
  return {
    type: types.GET_ANAMNESIS_UPDATE_SUMMARY_ERROR,
    payload: error
  };
};

export const getAnamnesisUpdateSummary = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getAnamnesisUpdateSummaryRequest());

    api
      .getAnamnesisUpdateSummary(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getAnamnesisUpdateSummarySuccess(response.response.body));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getAnamnesisUpdateSummaryError(error));
      });
  };
};

const getReferralPackagesRequest = () => {
  return {
    type: types.GET_REFERRAL_PACKAGES_REQUEST
  };
};

const getReferralPackagesSuccess = (response) => {
  return {
    type: types.GET_REFERRAL_PACKAGES_SUCCESS,
    payload: response
  };
};

const getReferralPackagesError = (error) => {
  return {
    type: types.GET_REFERRAL_PACKAGES_ERROR,
    payload: error
  };
};

export const getReferralPackages = (jwtToken) => {
  return (dispatch) => {
    dispatch(getReferralPackagesRequest());

    api
      .getReferralPackages(jwtToken)
      .then((response) => {
        dispatch(getReferralPackagesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getReferralPackagesError(error));
      });
  };
};

const getMemberPaymentHistoryRequest = () => {
  return {
    type: types.GET_PAYMENT_HISTORY_REQUEST
  };
};

const getMemberPaymentHistorySuccess = (response) => {
  return {
    type: types.GET_PAYMENT_HISTORY_SUCCESS,
    payload: response
  };
};

const getMemberPaymentHistoryError = (response) => {
  return {
    type: types.GET_PAYMENT_HISTORY_ERROR,
    payload: response
  };
};

export const getMemberPaymentHistory = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberPaymentHistoryRequest());

    return api
      .getMemberPaymentHistory(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberPaymentHistorySuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberPaymentHistoryError(error));
      });
  };
};

const getMemberActionTasksSuccess = (response) => {
  return {
    type: types.GET_MEMBER_ACTION_TASKS_SUCCESS,
    payload: response
  };
};

export const getMemberActionTasks = (jwtToken, memberGuid) => {
  return (dispatch) => {
    api
      .getMemberActionTasks(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberActionTasksSuccess(response));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

/**
 * File actions
 */

const getMemberFilesRequest = () => {
  return {
    type: types.GET_MEMBER_FILES_REQUEST
  };
};

const getMemberFilesSuccess = (response) => {
  return {
    type: types.GET_MEMBER_FILES_SUCCESS,
    payload: response
  };
};

const getMemberFilesError = (error) => {
  return {
    type: types.GET_MEMBER_FILES_ERROR,
    payload: error
  };
};

export const getMemberFiles = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberFilesRequest());

    api
      .getMemberFiles(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberFilesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberFilesError(error));
      });
  };
};

const previewFileRequest = (id, contentType, filename) => {
  return {
    type: types.PREVIEW_FILE_REQUEST,
    payload: {
      id,
      contentType,
      filename
    }
  };
};

const previewFileSuccess = (response, filename) => {
  return {
    type: types.PREVIEW_FILE_SUCCESS,
    payload: {
      response,
      filename
    }
  };
};

const previewFileError = (error) => {
  return {
    type: types.PREVIEW_FILE_ERROR,
    payload: error
  };
};

export const previewFile = (jwtToken, memberGuid, fileId, filename, contentType, previewOptions) => {
  return (dispatch) => {
    dispatch(previewFileRequest(fileId, contentType, filename));
    dispatch(setFilePreviewProps(previewOptions || {}));

    api
      .downloadFile(jwtToken, memberGuid, fileId)
      .then((response) => {
        dispatch(previewFileSuccess(response, filename));
      })
      .catch((error) => {
        console.log(error);
        dispatch(previewFileError(error));
      });
  };
};

export const closeFilePreview = () => {
  return {
    type: types.CLOSE_FILE_PREVIEW
  };
};

export const toggleDocumentSigned = () => {
  return {
    type: types.TOGGLE_DOCUMENT_SIGNED
  };
};

const setFilePreviewProps = (previewOptions) => {
  return {
    type: types.SET_FILE_PREVIEW_PROPS,
    payload: previewOptions
  };
};

export const showUploadFileModal = () => {
  return {
    type: types.SHOW_UPLOAD_FILE_MODAL
  };
};

export const hideUploadFileModal = () => {
  return {
    type: types.HIDE_UPLOAD_FILE_MODAL
  };
};

const uploadFileRequest = () => {
  return {
    type: types.UPLOAD_FILE_REQUEST
  };
};

const uploadFileSuccess = (response, fileInputElement) => {
  return {
    type: types.UPLOAD_FILE_SUCCESS,
    payload: {
      response,
      fileInputElement
    }
  };
};

const uploadFileError = () => {
  return {
    type: types.UPLOAD_FILE_ERROR
  };
};

export const uploadFile = (jwtToken, memberGuid, filename, file, contentType, fileInputElement) => {
  return (dispatch) => {
    dispatch(uploadFileRequest());

    // Uploaded files are assumed to be PDFs for now
    if (filename.substring(filename.length - 4, filename.length) !== '.pdf') {
      filename += '.pdf';
    }

    api
      .uploadFile(jwtToken, memberGuid, filename, file, contentType)
      .then((response) => {
        dispatch(uploadFileSuccess(response, fileInputElement));
      })
      .catch((error) => {
        console.log(error);
        dispatch(uploadFileError(error));
        dispatch(showNotification(I18n.t('notification.upload_file.error'), 'error'));
      });
  };
};

export const selectContentType = (event) => {
  return {
    type: types.SELECT_CONTENT_TYPE,
    payload: event.target.value
  };
};

export const selectFileToUpload = (event) => {
  return (dispatch) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const fileReader = new FileReader();

    fileReader.onloadend = (e) => {
      if (e.target.readyState === FileReader.DONE) {
        const uint = new Uint8Array(e.target.result);
        let bytes = [];

        uint.forEach((byte) => bytes.push(byte.toString(16)));

        const hex = bytes.join('').toUpperCase();
        const unsupportedFileTypeSelected = getMimetype(hex) !== 'application/pdf';
        const filename = file.name || '';

        dispatch({
          type: types.SELECT_FILE_TO_UPLOAD,
          payload: {
            file: unsupportedFileTypeSelected ? null : file,
            filename,
            unsupportedFileTypeSelected
          }
        });
      }
    };

    const blob = file.slice(0, 4);
    fileReader.readAsArrayBuffer(blob);
  };
};

export const editFilenameBeforeUpload = (event) => {
  return {
    type: types.EDIT_FILENAME_BEFORE_UPLOAD,
    payload: event
  };
};

const postFileToChatRequest = () => {
  return {
    type: types.POST_FILE_TO_CHAT_REQUEST
  };
};

const postFileToChatSuccess = (response) => {
  return {
    type: types.POST_CHAT_MESSAGE_SUCCESS,
    payload: response
  };
};

const postFileToChatError = () => {
  return {
    type: types.POST_FILE_TO_CHAT_ERROR
  };
};

export const postFileToChat = (jwtToken, memberGuid, fileId, contentType, filename) => {
  return (dispatch) => {
    dispatch(postFileToChatRequest());
    const fileInfo = {
      id: fileId,
      contentType,
      filename,
      filePath: '',
      fileUrl: ''
    };

    const messageText = contentType;

    api
      .postChatMessage(jwtToken, memberGuid, { message: messageText }, fileInfo)
      .then((response) => {
        dispatch(postFileToChatSuccess(response));
        dispatch(closeFilePreview());
      })
      .catch((error) => {
        console.log(error);
        dispatch(postFileToChatError(error));
        dispatch(showNotification(I18n.t('notification.post_file_to_chat.error'), 'error'));
      });
  };
};

export const setPreviewNumPages = (numPages) => {
  return {
    type: types.PREVIEW_FILE_SET_NUM_PAGES,
    payload: numPages
  };
};

export const clearMemberFiles = () => {
  return {
    type: types.CLEAR_MEMBER_FILES
  };
};

export const showFullSizeImage = (imageSrc, previewOptions) => {
  return {
    type: types.SHOW_FULLSIZE_IMAGE,
    payload: { imageSrc, previewOptions }
  };
};

export const hideFullSizeImage = () => {
  return {
    type: types.HIDE_FULLSIZE_IMAGE
  };
};

export const setPrefilledDocumentData = (prefilledData) => {
  return {
    type: types.SET_PREFILLED_DOCUMENT_DATA,
    payload: prefilledData
  };
};

export const resetSelectedDocumentType = () => {
  return {
    type: types.RESET_SELECTED_DOCUMENT_TYPE
  };
};

export const selectDocumentType = (e) => {
  return {
    type: types.SELECT_DOCUMENT_TYPE,
    payload: e.target.value
  };
};

export const updateTemplateContentProperty = (property, event) => {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_TEMPLATE_CONTENT,
      payload: {
        property,
        event
      }
    });
  };
};

const generateDocumentRequest = () => {
  return {
    type: types.GENERATE_DOCUMENT_REQUEST
  };
};

const generateDocumentSuccess = (response, templateType) => {
  return {
    type: types.GENERATE_DOCUMENT_SUCCESS,
    payload: { response, templateType }
  };
};

const generateDocumentError = () => {
  return {
    type: types.GENERATE_DOCUMENT_ERROR
  };
};

export const generateDocument = (jwtToken, memberGuid, templateType, documentContent, recipient, done) => {
  return (dispatch) => {
    dispatch(generateDocumentRequest());
    api
      .generateDocument(jwtToken, memberGuid, templateType, documentContent, recipient)
      .then((response) => {
        dispatch(generateDocumentSuccess(response, templateType));
        dispatch(
          showNotification(
            I18n.t(
              `notification.${
                templateType === TEMPLATE_TYPE.diagnosisScreening ? 'screening_referral' : 'document'
              }_created.success`
            ),
            'success'
          )
        );
        done && done(response.id, response.filename, response.contentType);
      })
      .catch((error) => {
        console.log(error);
        dispatch(generateDocumentError(error));
        dispatch(
          showNotification(
            I18n.t(
              `notification.${
                templateType === TEMPLATE_TYPE.diagnosisScreening ? 'screening_referral' : 'document'
              }_created.error`
            ),
            'error'
          )
        );
      });
  };
};

const getDocumentTemplateContentRequest = () => {
  return {
    type: types.GET_DOCUMENT_TEMPLATE_CONTENT_REQUEST
  };
};

const getDocumentTemplateContentSuccess = (response) => {
  return {
    type: types.GET_DOCUMENT_TEMPLATE_CONTENT_SUCCESS,
    payload: response
  };
};

const getDocumentTemplateContentError = () => {
  return {
    type: types.GET_DOCUMENT_TEMPLATE_CONTENT_ERROR
  };
};

export const getDocumentTemplateContent = (jwtToken, memberGuid, templateType) => {
  return (dispatch) => {
    dispatch(getDocumentTemplateContentRequest());
    api
      .getDocumentTemplateContent(jwtToken, memberGuid, templateType)
      .then((response) => {
        dispatch(getDocumentTemplateContentSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getDocumentTemplateContentError(error));
      });
  };
};

/**
 * Dashboard actions
 */

const getCaregiverMetricsRequest = () => {
  return {
    type: types.GET_CAREGIVER_METRICS_REQUEST
  };
};

const getCaregiverMetricsSuccess = (response) => {
  return {
    type: types.GET_CAREGIVER_METRICS_SUCCESS,
    payload: response
  };
};

const getCaregiverMetricsError = (error) => {
  return {
    type: types.GET_CAREGIVER_METRICS_ERROR,
    payload: error
  };
};

export const getCaregiverMetrics = (jwtToken, period) => {
  return (dispatch) => {
    dispatch(getCaregiverMetricsRequest());
    api
      .getCaregiverMetrics(jwtToken, period)
      .then((response) => {
        dispatch(getCaregiverMetricsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getCaregiverMetricsError(error));
      });
  };
};

/**
 * Jira actions
 */

export const showJiraModal = () => {
  return {
    type: types.SHOW_JIRA_MODAL
  };
};

export const hideJiraModal = () => {
  return {
    type: types.HIDE_JIRA_MODAL
  };
};

const getMemberJiraIssuesRequest = () => {
  return {
    type: types.GET_MEMBER_JIRA_ISSUES_REQUEST
  };
};

const getMemberJiraIssuesSuccess = (response) => {
  return {
    type: types.GET_MEMBER_JIRA_ISSUES_SUCCESS,
    payload: response
  };
};

const getMemberJiraIssuesError = (error) => {
  return {
    type: types.GET_MEMBER_JIRA_ISSUES_ERROR,
    payload: error
  };
};

export const getMemberJiraIssues = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberJiraIssuesRequest());
    api
      .getMemberJiraIssues(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberJiraIssuesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberJiraIssuesError(error));
      });
  };
};

const postMemberJiraIssueRequest = () => {
  return {
    type: types.POST_MEMBER_JIRA_ISSUE_REQUEST
  };
};

const postMemberJiraIssueSuccess = (response) => {
  return {
    type: types.POST_MEMBER_JIRA_ISSUE_SUCCESS,
    payload: response
  };
};

const postMemberJiraIssueError = (error) => {
  return {
    type: types.POST_MEMBER_JIRA_ISSUE_ERROR,
    payload: error
  };
};

export const postMemberJiraIssue = (jwtToken, jiraIssue) => {
  return (dispatch) => {
    dispatch(postMemberJiraIssueRequest());
    api
      .postMemberJiraIssue(jwtToken, jiraIssue)
      .then((response) => {
        dispatch(postMemberJiraIssueSuccess(response));
        dispatch(showNotification(I18n.t(`notification.create_jira_issue.success`), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(postMemberJiraIssueError(error));
        dispatch(showNotification(I18n.t(`notification.create_jira_issue.error`), 'error'));
      });
  };
};

export const setJiraIssueType = (e) => {
  return {
    type: types.SET_JIRA_ISSUE_TYPE,
    payload: window.parseInt(e.target.value, 10)
  };
};

export const setJiraIssueDescription = (e) => {
  return {
    type: types.SET_JIRA_ISSUE_DESCRIPTION,
    payload: e.target.value
  };
};

/**
 * Caregiver actions
 */

const getCaregiverProfileImageRequest = () => {
  return {
    type: types.GET_CAREGIVER_PROFILE_IMAGE_REQUEST
  };
};

const getCaregiverProfileImageSuccess = (response) => {
  return {
    type: types.GET_CAREGIVER_PROFILE_IMAGE_SUCCESS,
    payload: response
  };
};

const getCaregiverProfileImageError = (error) => {
  return {
    type: types.GET_CAREGIVER_PROFILE_IMAGE_ERROR,
    payload: error
  };
};

export const getCaregiverProfileImage = (jwtToken, userGuid, profileImageId) => {
  return (dispatch) => {
    dispatch(getCaregiverProfileImageRequest());
    api
      .getFile(jwtToken, userGuid, profileImageId, 100, 100)
      .then((response) => {
        dispatch(getCaregiverProfileImageSuccess(response.response.body));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getCaregiverProfileImageError(error));
      });
  };
};

export const caregiverProfileImageUpdateSuccess = (response, isWebcamCapture) => {
  return {
    type: types.CAREGIVER_PROFILE_IMAGE_UPDATE_SUCCESS,
    payload: response,
    isWebcamCapture
  };
};

/**
 * Clinics
 */
const getClinicsRequest = () => {
  return {
    type: types.GET_CLINICS_REQUEST
  };
};

const getClinicsSuccess = (response) => {
  return {
    type: types.GET_CLINICS_SUCCESS,
    payload: response
  };
};

const getClinicsError = (error) => {
  return {
    type: types.GET_CLINICS_ERROR,
    payload: error
  };
};

export const getClinics = (jwtToken) => {
  return (dispatch) => {
    dispatch(getClinicsRequest());
    api
      .getClinics(jwtToken)
      .then((response) => {
        dispatch(getClinicsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getClinicsError(error));
      });
  };
};

/**
 * Misc UI actions
 */

export const collapseChat = () => {
  return {
    type: types.COLLAPSE_CHAT,
    payload: window.innerWidth
  };
};

export const expandChat = () => {
  return {
    type: types.EXPAND_CHAT,
    payload: window.innerWidth
  };
};

export const acknowledgeReleaseNotes = () => {
  return {
    type: types.ACKNOWLEDGE_RELEASE_NOTES
  };
};

export const acknowledgeLoginError = () => {
  return {
    type: types.ACKNOWLEDGE_LOGIN_ERROR
  };
};

export const windowResized = (innerWidth) => {
  return {
    type: types.WINDOW_RESIZED,
    payload: innerWidth
  };
};

export const disableInstance = (reason) => {
  return {
    type: types.DISABLE_INSTANCE,
    payload: reason
  };
};

export const setStompClient = (client) => {
  return {
    type: types.SET_STOMP_CLIENT,
    payload: client
  };
};

export const setBroadcastChannel = (bc) => {
  return {
    type: types.SET_BROADCAST_CHANNEL,
    payload: bc
  };
};

export const showCeModal = () => {
  return {
    type: types.SHOW_CE_MODAL
  };
};

export const hideCeModal = () => {
  return {
    type: types.HIDE_CE_MODAL
  };
};

export const toggleDssSection = (section) => {
  return {
    type: types.TOGGLE_DSS_SECTION,
    payload: section
  };
};

export const toggleCollapseAllDssSections = () => {
  return {
    type: types.TOGGLE_COLLAPSE_ALL_DSS_SECTIONS
  };
};

export const showMaintenanceModal = () => {
  return {
    type: types.SHOW_MAINTENTANCE_MODAL
  };
};

export const hideMaintenanceModal = () => {
  return {
    type: types.HIDE_MAINTENTANCE_MODAL
  };
};

export const showDeployInProgressModal = () => {
  return {
    type: types.SHOW_DEPLOY_IN_PROGRESS_MODAL
  };
};

export const hideDeployInProgressModal = () => {
  return {
    type: types.HIDE_DEPLOY_IN_PROGRESS_MODAL
  };
};

export const showMaintenanceCompleteModal = () => {
  return {
    type: types.SHOW_MAINTENANCE_COMPLETE_MODAL
  };
};

export const hideMaintenanceCompleteModal = () => {
  return {
    type: types.HIDE_MAINTENANCE_COMPLETE_MODAL
  };
};

export const toggleCollapseMainMenu = () => {
  return {
    type: types.TOGGLE_MAIN_MENU_COLLAPSE
  };
};

/**
 * Misc.
 */

const healthPingSuccess = () => {
  return {
    type: types.HEALTH_PING_SUCCESS
  };
};

export const healthPing = () => {
  return (dispatch) => {
    api
      .healthPing()
      .then(() => {
        dispatch(healthPingSuccess());
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const resetHealthPingSuccesses = () => {
  return {
    type: types.RESET_HEALH_PING_SUCCESSES
  };
};

export const notifyClearDss = () => {
  return {
    type: types.NOTIFY_CLEAR_DSS
  };
};

/**
 * Patient communication actions
 */
const getMemberCommunicationEventsRequest = () => {
  return {
    type: types.GET_MEMBER_COMMUNICATION_EVENTS_REQUEST
  };
};

const getMemberCommunicationEventsSuccess = (response) => {
  return {
    type: types.GET_MEMBER_COMMUNICATION_EVENTS_SUCCESS,
    payload: response
  };
};

const getMemberCommunicationEventsError = (error) => {
  return {
    type: types.GET_MEMBER_COMMUNICATION_EVENTS_ERROR,
    payload: error
  };
};

export const getMemberCommunicationEvents = (jwtToken, memberGuid, paginationOffSet, paginationLimit) => {
  return (dispatch) => {
    dispatch(getMemberCommunicationEventsRequest());
    api
      .getMemberCommunicationEvents(jwtToken, memberGuid, paginationOffSet, paginationLimit)
      .then((response) => {
        dispatch(getMemberCommunicationEventsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberCommunicationEventsError(error));
      });
  };
};

const getMemberFullCommunicationEventRequest = () => {
  return {
    type: types.GET_MEMBER_FULL_COMMUNICATION_EVENT_REQUEST
  };
};

const getMemberFullCommunicationEventSuccess = (response) => {
  return {
    type: types.GET_MEMBER_FULL_COMMUNICATION_EVENT_SUCCESS,
    payload: response
  };
};

const getMemberFullCommunicationEventError = (error) => {
  return {
    type: types.GET_MEMBER_FULL_COMMUNICATION_EVENT_ERROR,
    payload: error
  };
};

export const getMemberFullCommunicationEvent = (jwtToken, memberGuid, id) => {
  return (dispatch) => {
    dispatch(getMemberFullCommunicationEventRequest(id));
    api
      .getMemberFullCommunicationEvent(jwtToken, memberGuid, id)
      .then((response) => {
        dispatch(getMemberFullCommunicationEventSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberFullCommunicationEventError(error));
      });
  };
};

export const setPatientCommunicationViewCurrentPage = (pageNumber) => {
  return {
    type: types.SET_PATIENT_COMMUNICATION_VIEW_CURRENT_PAGE,
    payload: pageNumber
  };
};

export const showCommunicationEventsContentModal = (id) => {
  return {
    type: types.SHOW_COMMUNICATION_EVENTS_CONTENT_MODAL,
    payload: id
  };
};

export const hideCommunicationEventsContentModal = () => {
  return {
    type: types.HIDE_COMMUNICATION_EVENTS_CONTENT_MODAL
  };
};

const getMemberMonitorsRequest = () => {
  return {
    type: types.GET_MEMBER_MONITORS_REQUEST
  };
};

const getMemberMonitorsSuccess = (response) => {
  return {
    type: types.GET_MEMBER_MONITORS_SUCCESS,
    payload: response
  };
};

const getMemberMonitorsError = (error) => {
  return {
    type: types.GET_MEMBER_MONITORS_ERROR,
    payload: error
  };
};

export const getMemberMonitors = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberMonitorsRequest());
    api
      .getMemberMonitors(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberMonitorsSuccess(response.response.body));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberMonitorsError(error));
      });
  };
};

/**
 * Physical examination referral actions
 */

const postPhysicalExaminationReferralRequest = () => {
  return {
    type: types.POST_PHYSICAL_EXAMINATION_REFERRAL_REQUEST
  };
};

const postPhysicalExaminationReferralSuccess = (response) => {
  return {
    type: types.POST_PHYSICAL_EXAMINATION_REFERRAL_SUCCESS,
    payload: response
  };
};

const postPhysicalExaminationReferralError = (error) => {
  return {
    type: types.POST_PHYSICAL_EXAMINATION_REFERRAL_ERROR,
    payload: error
  };
};

export const postPhysicalExaminationReferral = (jwtToken, memberGuid, referralRecipient) => {
  return (dispatch) => {
    dispatch(postPhysicalExaminationReferralRequest());

    api
      .postPhysicalExaminationReferral(jwtToken, memberGuid, referralRecipient)
      .then((response) => {
        dispatch(postPhysicalExaminationReferralSuccess(response));
        dispatch(showNotification(I18n.t('notification.post_physical_examination_referral.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(postPhysicalExaminationReferralError(error));
        dispatch(showNotification(I18n.t('notification.post_physical_examination_referral.error'), 'error'));
      });
  };
};

/**
 * Examination actions
 */

const getCardiopulmonaryExaminationRequest = () => {
  return {
    type: types.GET_CARDIOPULMONARY_EXAMINATION_REQUEST
  };
};

const getCardiopulmonaryExaminationSuccess = (response) => {
  return {
    type: types.GET_CARDIOPULMONARY_EXAMINATION_SUCCESS,
    payload: response
  };
};

const getCardiopulmonaryExaminationError = (error) => {
  return {
    type: types.GET_CARDIOPULMONARY_EXAMINATION_ERROR,
    payload: error
  };
};

export const getCardiopulmonaryExamination = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getCardiopulmonaryExaminationRequest());
    api
      .getCardiopulmonaryExamination(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getCardiopulmonaryExaminationSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getCardiopulmonaryExaminationError(error));
      });
  };
};

/**
 * Assessment actions
 */

const getKioskAssessmentsError = (error) => {
  return {
    type: types.GET_KIOSK_ASSESSMENTS_ERROR,
    payload: error
  };
};

export const setRemainingKioskAssessmentCount = (count) => {
  return {
    type: types.SET_REMAINING_KIOSK_ASSESSMENT_COUNT,
    payload: count
  };
};

export const getKioskAssessmentCount = (jwtToken) => {
  return (dispatch) => {
    api
      .getKioskAssessments(jwtToken, ASSESSMENT_STATUS.AwaitingAssessment)
      .then((response) => {
        dispatch(setRemainingKioskAssessmentCount(response?.pagination?.totalNumberOfItems));
      })
      .catch((error) => {
        console.log(error);
        if (error?.status === HTTP_STATUS_CODES.NOT_FOUND) {
          dispatch(setRemainingKioskAssessmentCount(0));
        } else {
          dispatch(getKioskAssessmentsError(error));
        }
      });
  };
};

export const setInitialKioskAssessment = (assessment) => {
  return {
    type: types.SET_INITIAL_KIOSK_ASSESSMENT,
    payload: assessment
  };
};

export const completeKioskAssessment = () => {
  return {
    type: types.COMPLETE_KIOSK_ASSESSMENT
  };
};

const getAuscultationAssessmentsRequest = () => {
  return {
    type: types.GET_AUSCULTATION_ASSESSMENTS_REQUEST
  };
};

const getAuscultationAssessmentsSuccess = (response) => {
  return {
    type: types.GET_AUSCULTATION_ASSESSMENTS_SUCCESS,
    payload: response
  };
};

const getAuscultationAssessmentsError = (error) => {
  return {
    type: types.GET_AUSCULTATION_ASSESSMENTS_ERROR,
    payload: error
  };
};

export const getAuscultationAssessments = (jwtToken) => {
  return (dispatch) => {
    dispatch(getAuscultationAssessmentsRequest());
    api
      .getAuscultationAssessments(jwtToken)
      .then((response) => {
        dispatch(getAuscultationAssessmentsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getAuscultationAssessmentsError(error));
      });
  };
};

export const removeAuscultationAssessment = (assessmentId) => {
  return {
    type: types.REMOVE_AUSCULTATION_ASSESSMENT,
    payload: assessmentId
  };
};
